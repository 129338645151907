import { faAdd } from '@fortawesome/free-solid-svg-icons/faAdd';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faListCheck } from '@fortawesome/free-solid-svg-icons/faListCheck';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { SortableChild } from '../../../components/dnd/SortableChild';
import i18n, { AppLangs } from '../../../i18n';
import { KanbanCard as KanbanCardModel } from '../../../model/kanban/KanbanCard';
import { KanbanColumn } from '../../../model/kanban/KanbanColumn';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { useKanbanContext } from '../kanban-context';

const StyledKanbanCard = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  padding: ${Spacing.s};
  background-color: ${(props) => props.theme.white};
  border-radius: ${Rounded.xl};
  width: 100%;
`;

const KanbanCardActionsWrapper = styled(Flex)`
  gap: ${Spacing.s};
  align-items: flex-start;
`;

const KnabanCardName = styled(Text)`
  font-size: 1.2rem;
  font-weight: bold;
`;

const KanbanCardActionButton = styled(Button)`
  padding: 0;
  background-color: ${(props) => props.theme.transparent};
  margin: 0;
  color: ${(props) => props.theme.main};
`;

type EntriesDealsDealPros = {
  card: KanbanCardModel;
  column?: KanbanColumn;
};

export const KanbanCard: React.FC<EntriesDealsDealPros> = ({
  card,
  column,
}) => {
  const { t } = useTranslation();
  const {
    setCard,
    setReadDrawerVisible,
    setUpdateDrawerVisible,
    setTasksReadDrawerVisible,
    setTaskCreateDrawerVisible,
  } = useKanbanContext();

  const actionsMarginSide = {
    marginRight:
      i18n.language === AppLangs.AR || column?.default ? '0' : '1.1rem',
    marginLeft:
      i18n.language === AppLangs.AR && !column?.default ? Spacing.l : '0',
  };

  const canCreateTask =
    !card.kanbanTasks?.length || card.kanbanTasks.length < 3;

  const onCardClick = (fn: ATVoidFunction<boolean>) => {
    setCard(card);
    fn(true);
  };

  return (
    <SortableChild id={card._id} showDragIcon={!column?.default} key={card._id}>
      <StyledKanbanCard>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          gap="s"
          width={'100%'}
        >
          <Flex justifyContent="space-between">
            <Text cutText>{card.amount}$</Text>

            <KanbanCardActionsWrapper style={actionsMarginSide}>
              {canCreateTask ? (
                <Tooltip title={t('common.task_create')}>
                  <KanbanCardActionButton
                    onClick={() => onCardClick(setTaskCreateDrawerVisible)}
                    icon={faAdd}
                    fontSize="1rem"
                  />
                </Tooltip>
              ) : null}

              <Tooltip title={t('tasks.read.drawer.title')}>
                <KanbanCardActionButton
                  onClick={() => onCardClick(setTasksReadDrawerVisible)}
                  icon={faListCheck}
                  fontSize="1rem"
                />
              </Tooltip>

              <Tooltip title={t('common.preview')}>
                <KanbanCardActionButton
                  onClick={() => onCardClick(setReadDrawerVisible)}
                  icon={faEye}
                  fontSize="1rem"
                />
              </Tooltip>

              <Tooltip title={t('common.edit')}>
                <KanbanCardActionButton
                  onClick={() => onCardClick(setUpdateDrawerVisible)}
                  icon={faPenToSquare}
                  fontSize="1rem"
                />
              </Tooltip>
            </KanbanCardActionsWrapper>
          </Flex>

          <Flex>
            <Flex width={'50%'}>
              <KnabanCardName cutText>{card.title}</KnabanCardName>
            </Flex>
            <Flex width={'50%'} justifyContent="flex-end"></Flex>
          </Flex>
          <Flex flexDirection="column" width={'100%'}>
            <Text cutText>
              {card.entry?.fields?.name || card.entry?.fields?.email}
            </Text>
          </Flex>
        </Flex>

        {card.closingDate ? (
          <Flex justifyContent="flex-end">
            <Text>{standardDate(card.closingDate)}</Text>
          </Flex>
        ) : null}
      </StyledKanbanCard>
    </SortableChild>
  );
};
