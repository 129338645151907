import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Images } from '../../assets';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { Modal } from '../../components/modal/Modal';
import i18n, { AppLangs } from '../../i18n';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const websiteLanguage = i18n.language;

const HeroSection = styled(Flex)`
  position: relative;
  z-index: 1;
  padding-inline: ${Spacing.s};
  padding-block: ${Spacing.m};
  background-image: url(${Images.HomeBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.xxl};
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    padding-inline: calc(${Spacing.xxxl} * 1.2);
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    min-height: 95vh;
  }
`;

const HeroTitle = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-weight: bolder;
  font-size: 2.5rem;
  text-align: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    text-align: start;
  }
`;

const TagLineText = styled(Text)`
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.75rem;
    text-align: start;
  }
`;

const HeroParagraph = styled(Text)`
  font-size: 1.25rem;
  line-height: 1.75rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const HeroBackgroundBlur = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(${Images.HeroBackground});
  background-image: none;
  z-index: -1;
`;

const HeroPart = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 47%;
  }
`;

const HeroLeftPart = styled(HeroPart)`
  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-left: ${Spacing.l};
  }
`;

const HeroC2AWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: ${Spacing.m};
  @media (min-width: ${Breakpoints.DESKTOP}) {
    justify-content: flex-start;
  }
`;

const CallToAction = styled(Button)`
  background-color: ${(props) => props.theme.darkPink};
  border: 1px solid ${(props) => props.theme.darkPink};
  padding: 0.75rem 1rem;
  font-size: 0.7rem;
  font-weight: 500;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.lightMain};
    border: 1px solid ${(props) => props.theme.lightMain};
  }
  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }
`;

const SecondCallToAction = styled(Button)`
  color: ${(props) => props.theme.black};
  background-color: ${(props) => props.theme.transparent};
  border: 1px solid ${(props) => props.theme.black};
  padding: 0.75rem 1rem;
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.black};
    color: ${(props) => props.theme.white};
  }
  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }
`;

const HeroImageWrapper = styled(Flex)`
  border-radius: ${Rounded.circle};
  max-width: 20rem;
  padding: ${Spacing.m};
  background-color: ${(props) => props.theme.darkPink};
  position: relative;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding: 2.5rem;
    max-width: 30rem;
    margin-left: ${websiteLanguage === AppLangs.AR ? '0' : 'auto'};
    margin-right: ${websiteLanguage === AppLangs.AR ? 'auto' : '0'};
  }
`;

const HeroCircleAnimation = keyframes`
  from {
    transform: translateY(-1rem);
  } to {
    transform: translateY(1rem);
  }
`;

const HeroImageLeftCircle = styled(Flex)`
  height: 4rem;
  width: 4rem;
  position: absolute;
  bottom: 5rem;
  left: 0;
  border-radius: ${Rounded.circle};
  background-size: 20rem;
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 1.5s;
  @media (min-width: ${Breakpoints.TABLET}) {
    height: 6rem;
    width: 6rem;
  }
`;

const HeroImageRightCircle = styled(Flex)`
  height: 5rem;
  width: 5rem;
  background-color: ${(props) => props.theme.darkPink};
  position: absolute;
  bottom: 5rem;
  right: 0;
  border-radius: ${Rounded.circle};
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 2s;

  @media (min-width: ${Breakpoints.TABLET}) {
    height: 8rem;
    width: 8rem;
    right: -2rem;
  }
`;

const Section = styled(Flex)`
  flex: 1;
  padding-inline: ${Spacing.m};
  padding-block: ${Spacing.l};
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.xxl};
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    padding-inline: calc(${Spacing.xxxl} * 1.2);
  }
`;

const ResponsiveFlex = styled(Flex)`
  width: 100%;
  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 47%;
  }
`;

const Paragraph = styled(Text)`
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const LandingPage = () => {
  const [bookDemoModalVisible, setBookDemoModalVisible] = useState(false);
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Flex flexDirection="column">
      <HeroSection>
        <Flex
          alignItems="center"
          justifyContent="center"
          marginTop="l"
          flexWrap="wrap"
        >
          <HeroBackgroundBlur />

          <HeroLeftPart>
            <Flex gap="s" flexDirection="column">
              <HeroTitle>{t('app.name')}</HeroTitle>

              <TagLineText>{t('app.description')}</TagLineText>

              <HeroParagraph>{t('front_page.hero.paragraph')}</HeroParagraph>

              <HeroC2AWrapper>
                <CallToAction onClick={() => navigate('/signup')}>
                  {t('front_page.hero.main_c2a')}
                </CallToAction>
                <SecondCallToAction
                  onClick={() => setBookDemoModalVisible(true)}
                >
                  {t('front_page.hero.sub_c2a')}
                </SecondCallToAction>
              </HeroC2AWrapper>
            </Flex>
          </HeroLeftPart>

          <HeroPart>
            <HeroImageWrapper>
              <Flex
                width="100%"
                height="100%"
                borderRadius="circle"
                overflow="hidden"
              >
                <AtiraImage
                  src={
                    heroImageLoaded ? Images.HeroPhoto : Images.WhitePlaceholder
                  }
                  width="100%"
                  height="100%"
                  onLoad={() => setHeroImageLoaded(true)}
                />
              </Flex>

              <HeroImageLeftCircle>
                <AtiraImage src={Images.HomeFirstCircle} />
              </HeroImageLeftCircle>

              <HeroImageRightCircle>
                <AtiraImage src={Images.HomeSecondCircle} />
              </HeroImageRightCircle>
            </HeroImageWrapper>
          </HeroPart>
        </Flex>
      </HeroSection>

      <Section>
        <ResponsiveFlex>
          <AtiraImage src={Images.HomeFirstSection} />
        </ResponsiveFlex>

        <ResponsiveFlex>
          <Flex flex={1} flexDirection="column" gap="m" justifyContent="center">
            <Paragraph>{t('front_page.section.1.paragraph.1')}</Paragraph>

            <Paragraph>{t('front_page.section.1.paragraph.2')}</Paragraph>
          </Flex>
        </ResponsiveFlex>
      </Section>

      <Section>
        <ResponsiveFlex>
          <Flex flex={1} alignItems="center">
            <Paragraph>{t('front_page.section.2.paragraph')}</Paragraph>
          </Flex>
        </ResponsiveFlex>
        <ResponsiveFlex>
          <AtiraImage src={Images.HomeSecondSection} />
        </ResponsiveFlex>
      </Section>

      <Modal
        title={t('front_page.hero.sub_c2a')}
        open={bookDemoModalVisible}
        onClose={() => setBookDemoModalVisible(false)}
      >
        <iframe
          title="Book a demo"
          src="https://mehde.zohobookings.eu/portal-embed#/165335000000833032"
          width={'100%'}
          height={'600px'}
          style={{ border: '0', marginTop: Spacing.xl }}
        ></iframe>
      </Modal>
    </Flex>
  );
};
