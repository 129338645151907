import React, { ReactNode, createContext, useContext, useState } from 'react';

import { KanbanTask } from '../../model/kanban/KanbanTask';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';

type Task =
  | Omit<LeadTask, 'entryId'>
  | Omit<ContactTask, 'entryId'>
  | Omit<KanbanTask, 'kanbanCardId'>;

interface TaskContextType {
  task: Task | null;
  setTask: ATVoidFunction<Task>;
  //
  contactTaskUpdateDrawerVisible: boolean;
  setContactTaskUpdateDrawerVisible: ATVoidFunction<boolean>;
  contactTasksReadDrawerVisible: boolean;
  setContactTasksReadDrawerVisible: ATVoidFunction<boolean>;
  leadTasksReadDrawerVisibile: boolean;
  setLeadTasksReadDrawerVisible: ATVoidFunction<boolean>;
  leadTaskUpdateDrawerVisibile: boolean;
  setLeadTaskUpdateDrawerVisible: ATVoidFunction<boolean>;
  leadTaskCreateDrawerVisible: boolean;
  setLeadTaskCreateDrawerVisible: ATVoidFunction<boolean>;
  deleteTaskWarningModalVisible: boolean;
  setDeleteTaskWarningModalVisible: ATVoidFunction<boolean>;
}

const TaskContext = createContext<TaskContextType | undefined>(undefined);

export const useTaskContext = () => {
  const context = useContext(TaskContext);

  if (!context) {
    throw new Error('useTaskContext must be used within a EntriesProvider');
  }

  return context;
};

interface TaskContextProviderProps {
  children: ReactNode;
}

export const TaskContextProvider: React.FC<TaskContextProviderProps> = ({
  children,
}) => {
  const [task, setTask] = useState<Task | null>(null);
  //
  const [contactTaskUpdateDrawerVisible, setContactTaskUpdateDrawerVisible] =
    useState(false);
  const [contactTasksReadDrawerVisible, setContactTasksReadDrawerVisible] =
    useState(false);
  const [leadTasksReadDrawerVisibile, setLeadTasksReadDrawerVisible] =
    useState(false);
  const [leadTaskUpdateDrawerVisibile, setLeadTaskUpdateDrawerVisible] =
    useState(false);
  const [leadTaskCreateDrawerVisible, setLeadTaskCreateDrawerVisible] =
    useState(false);
  const [deleteTaskWarningModalVisible, setDeleteTaskWarningModalVisible] =
    useState(false);

  return (
    <TaskContext.Provider
      value={{
        task,
        setTask,
        //
        contactTaskUpdateDrawerVisible,
        setContactTaskUpdateDrawerVisible,
        contactTasksReadDrawerVisible,
        setContactTasksReadDrawerVisible,
        leadTasksReadDrawerVisibile,
        setLeadTasksReadDrawerVisible,
        leadTaskUpdateDrawerVisibile,
        setLeadTaskUpdateDrawerVisible,
        leadTaskCreateDrawerVisible,
        setLeadTaskCreateDrawerVisible,
        deleteTaskWarningModalVisible,
        setDeleteTaskWarningModalVisible,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
