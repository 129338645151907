import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { StepsProps } from 'antd';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AtiraStepper } from '../../components/AtiraStepper';
import { Flex } from '../../components/Flex';
import { StepperStep } from '../../components/StepperStep';
import { SubHeader } from '../../components/SubHeader';
import i18n from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { CreateFormDtoInputNames } from '../../model/form/types/CreateFormDtoInputNames.enum';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { FormTheme } from '../../model/form/types/FormThemes.enum';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { Direction } from '../../model/shared/Direction.enum';
import { useAppSelector } from '../../redux/store';
import { FormCreateStepBasicInfo } from './FormCreateStepBasicInfo';
import { FormCreateStepInputs } from './FormCreateStepInputs';
import { FormCreateStepPostSubmitType } from './FormCreateStepPostSubmitType';
import { FormCreateStepTheme } from './FormCreateStepTheme';
import { canCreateForm } from './FormUtils';

export const FormCreate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const websiteLanguage = i18n.language;

  const formMethods = useForm<CreateFormDto>({
    defaultValues: {
      inputs: [
        {
          name: CreateFormDtoInputNames.NAME,
          label: CreateFormDtoInputNames.NAME,
          required: true,
          type: InputTypes.TEXT.toLowerCase() as InputTypes,
        },
        {
          name: CreateFormDtoInputNames.EMAIL,
          label: CreateFormDtoInputNames.EMAIL,
          required: true,
          type: InputTypes.EMAIL.toLowerCase() as InputTypes,
        },
        {
          name: CreateFormDtoInputNames.PHONE,
          label: CreateFormDtoInputNames.PHONE,
          required: false,
          type: InputTypes.NUMBER.toLowerCase() as InputTypes,
        },
        {
          name: CreateFormDtoInputNames.MESSAGE,
          label: CreateFormDtoInputNames.MESSAGE,
          required: false,
          type: InputTypes.TEXT.toLowerCase() as InputTypes,
        },
        {
          name: CreateFormDtoInputNames.DATE,
          label: CreateFormDtoInputNames.DATE,
          required: false,
          type: InputTypes.DATE.toLowerCase() as InputTypes,
        },
      ],
      postSubmit: FormPostSubmit.redirect,
      domains: [{ content: '' }],
      theme: FormTheme.WHITE,
      title: '',
      formDirection: Direction.LTR,
    },
  });
  const [title, domains] = formMethods.watch(['title', 'domains']);

  const state = useAppSelector((s) => s);

  useEffect(() => {
    if (!canCreateForm(state)) {
      navigate('/forms', { replace: true });
    }
  }, [navigate, state]);

  // Use below as a reference to disable steps
  const stepperItemsDisabled = [
    !title?.trim() || !domains[0].content,
    false,
    false,
  ];
  const stepperItems: StepsProps['items'] = [
    {
      title: t('forms.create.step.1'),
      status: !title?.trim() || !domains[0].content ? 'error' : 'finish',
    },
    {
      title: t('forms.create.step.2'),
      disabled: stepperItemsDisabled[0],
    },
    {
      title: t('forms.create.step.3'),
      disabled: stepperItemsDisabled[0] || stepperItemsDisabled[1],
    },
    {
      title: t('forms.create.step.4'),
      disabled:
        stepperItemsDisabled[0] ||
        stepperItemsDisabled[1] ||
        stepperItemsDisabled[2],
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Flex width={'100%'} flexDirection="column" flex={1}>
        <SubHeader
          title={`${t('common.forms')} / ${t('common.create')}`}
          icon={websiteLanguage === 'ar' ? faArrowRight : faArrowLeft}
          buttonTitle={t('common.back')}
          onClick={() => navigate('/forms')}
        />
        <AtiraStepper
          components={[
            <StepperStep
              title={t('forms.create.step.1')}
              children={<FormCreateStepBasicInfo />}
            />,
            <StepperStep
              title={t('forms.create.step.2')}
              children={<FormCreateStepInputs />}
            />,
            <StepperStep
              title={t('forms.create.step.3')}
              children={<FormCreateStepPostSubmitType />}
            />,
            <StepperStep
              title={t('forms.create.step.4')}
              children={<FormCreateStepTheme />}
            />,
          ]}
          items={stepperItems}
        />
      </Flex>
    </FormProvider>
  );
};
