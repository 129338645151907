import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JoyRide, { BeaconRenderProps, Callback, Step } from 'react-joyride';
import styled, { keyframes, useTheme } from 'styled-components';

import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { AtriaTourClasses } from './AtiraTourClasses.enum';

const steps = [
  {
    target: AtriaTourClasses.MENU_BUTTON,
    content: 'tour.menu.button',
    placement: 'top',
  },
  {
    target: AtriaTourClasses.MENU_DASHBOARD,
    content: 'tour.menu.dashboard',
  },
  {
    target: AtriaTourClasses.MENU_FORMS,
    content: 'tour.menu.forms',
  },
  {
    target: AtriaTourClasses.MENU_ENTRIES,
    content: 'tour.menu.entries',
  },
  {
    target: AtriaTourClasses.MENU_DEALS,
    content: 'tour.menu.deals',
  },
  {
    target: AtriaTourClasses.MENU_REPORTS,
    content: 'tour.menu.reports',
  },
  {
    target: AtriaTourClasses.MENU_TASKS,
    content: 'tour.menu.tasks',
  },
  {
    target: AtriaTourClasses.WIDGET_STATS,
    content: 'tour.widget.stats',
  },
  {
    target: AtriaTourClasses.WIDGET_LATEST_ENTRIES,
    content: 'tour.widget.latest_entries',
  },
];

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: rgba(235, 235, 235, 0.9); 
    transform: scale(1.6);
  }
`;

const Beacon = styled.span`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: rgba(235, 235, 235, 0.6);
  border-radius: ${Rounded.circle};
  display: inline-block;
  height: 2rem;
  width: 2rem;
  position: relative;
  top: 1.25rem;
`;

export const AtiraTour: React.FC = () => {
  const [showTour, setShowTour] = useState<boolean>();
  const { t } = useTranslation();
  const theme = useTheme();

  const onStepChange: Callback = (data) => {
    const { type, status, action } = data;
    if ((type === 'tour:end' && status === 'finished') || action === 'skip') {
      localStorage.setItem(process.env.REACT_APP_TOUR!, 'false');
      setShowTour(false);
    }
  };

  useEffect(() => {
    const storedShowTour = localStorage.getItem(process.env.REACT_APP_TOUR!);
    setShowTour(JSON.parse(storedShowTour || 'true'));
  }, []);

  if (!showTour) {
    return null;
  }

  return (
    <JoyRide
      steps={
        steps.map((item) => ({
          ...item,
          target: `.${item.target}`,
          content: t(item.content),
        })) as Step[]
      }
      continuous
      disableScrollParentFix
      disableScrolling
      showProgress
      showSkipButton
      spotlightPadding={0}
      beaconComponent={Beacon as React.FC<BeaconRenderProps>}
      styles={{
        buttonNext: { background: theme.main, borderRadius: Rounded.sm },
        buttonBack: {
          background: theme.main,
          color: theme.white,
          marginInline: Spacing.s,
          borderRadius: Rounded.sm,
        },
        buttonClose: { display: 'none' },
        beacon: { backgroundColor: theme.main },
      }}
      spotlightClicks
      callback={onStepChange}
      locale={{
        back: t('tour.button.back'),
        close: t('tour.button.close'),
        last: t('tour.button.last'),
        next: t('tour.button.next'),
        skip: t('tour.button.skip'),
      }}
    />
  );
};
