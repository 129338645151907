import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraEmpty } from '../../components/AtiraEmpty';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { Text } from '../../components/Text';
import { WarningModal } from '../../components/WarningModal';
import { Form } from '../../model/form/Form';
import { FormLimitByUserKind } from '../../model/form/types/FormLimitByUserKind';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { formSliceSelectors } from '../../redux/forms/form.selector';
import { formActions } from '../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { EntryCreateModal } from '../entries/components/EntryCreateModal';
import { canCreateForm } from './FormUtils';
import { FormReadModal } from './components/FormReadModal';
import { FormShareModal } from './components/ShareFormModal';
import { UserForm } from './components/UserForm';

const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 ${Spacing.m};
  flex-direction: column;
  gap: ${() => `${Spacing.m}`};
  width: 100%;
  height: 100%;
  align-content: space-between;
`;

export const UserForms: React.FC = () => {
  const { t } = useTranslation();

  const [hoveredFormId, setHoveredFormId] = useState<string | null>(null);
  const [loading, setloading] = useState(false);
  const [formShareModalVisible, setFormShareModalVisible] = useState(false);
  const [formReadModalVisible, setFormReadModalVisible] = useState(false);
  const [formDeleteModalVisible, setFormDeleteModalVisible] = useState(false);
  const [entryCreateModalVisible, setEntryCreateModalVisible] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userForms = useAppSelector(formSliceSelectors.selectMyForms)?.filter(
    (f) => f.orphan !== true,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const state = useAppSelector((s) => s);
  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const getCanCreateForm = () => {
    if (user.kind === UserKind.FREE) {
      return userForms.length <= FormLimitByUserKind.FREE;
    } else {
      return userForms.length < FormLimitByUserKind.FREE;
    }
  };

  const createNewForm = () => {
    if (getCanCreateForm()) {
      navigate('/forms/create');
    } else {
      if (user.kind === UserKind.FREE) {
        AtiraToast.error(t('forms.create.limit.free'));
      } else {
        AtiraToast.error(t('forms.create.limit.paid'));
      }
    }
  };

  const openShareModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setFormShareModalVisible(true);
  };

  const openPreviewModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setFormReadModalVisible(true);
  };

  const navigateToFormEntries: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    navigate('/entries', { state: { useFilter: form } });
  };

  const openCreateNewEntryModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setEntryCreateModalVisible(true);
  };

  const openDeleteFormModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setFormDeleteModalVisible(true);
  };

  const deleteForm = async () => {
    try {
      if (!currentForm) {
        return AtiraToast.error('Missing Form!');
      }

      setloading(true);
      await dispatch(
        formActions.deleteForm({ formId: currentForm?._id!, userId: userId }),
      ).unwrap();
      await dispatch(formActions.getMyForms({ userId: userId })).unwrap();
      setFormDeleteModalVisible(false);
      AtiraToast.success(t('forms.form.delete.success'));
    } catch (error: any) {
      console.log(error);
      AtiraToast.apiError(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    dispatch(formActions.getMyForms({ userId: userId }));
  }, [dispatch, userId]);

  return (
    <Flex flexDirection="column" justifyContent="space-between" flex={1}>
      <Flex flexDirection="column">
        <SubHeader
          title={t('common.forms')}
          icon={faPlus}
          buttonTitle={t('common.create')}
          onClick={createNewForm}
          enabled={canCreateForm(state)}
        />

        <Wrapper>
          {userForms.length
            ? userForms.map((f, i) => (
                <UserForm
                  key={f._id}
                  form={f}
                  onHover={setHoveredFormId}
                  hoveredFormId={hoveredFormId}
                  onDelete={openDeleteFormModal}
                  onShare={openShareModal}
                  onPreview={openPreviewModal}
                  onNavigateToEntries={navigateToFormEntries}
                  onCreateEntry={openCreateNewEntryModal}
                />
              ))
            : null}
        </Wrapper>
      </Flex>

      {!userForms.length ? (
        <Flex alignSelf="center">
          <AtiraEmpty description={t('forms.no_forms_yet')} />
        </Flex>
      ) : null}

      <Flex marginBottom="s" justifyContent="center">
        <Text color="darkerSub" fontSize="m">
          {t('form.forms_limit_note')}
        </Text>
      </Flex>

      <FormShareModal
        isOpen={formShareModalVisible}
        onClose={() => setFormShareModalVisible(false)}
      />

      <FormReadModal
        isOpen={formReadModalVisible}
        onClose={() => setFormReadModalVisible(false)}
      />

      <WarningModal
        isOpen={formDeleteModalVisible}
        title={t('forms.delete.modal.title')}
        onConfirm={deleteForm}
        loading={loading}
        onClose={() => setFormDeleteModalVisible(false)}
      />

      <EntryCreateModal
        isOpen={entryCreateModalVisible}
        onClose={() => setEntryCreateModalVisible(false)}
        key={currentForm?._id}
      />
    </Flex>
  );
};
