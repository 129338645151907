import { KanbanTask } from '../../model/kanban/KanbanTask';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';
import { GenericGetTasksDto } from '../../model/task/dto/GenericGetTasksDto';
import { AtiraApi } from '../AxiosClient';

export class TaskAxios {
  static getLeadTasks(dto: GenericGetTasksDto) {
    return AtiraApi.post<PageResponseDto<LeadTask[]>>(
      `/task/lead/all`,
      dto,
    ).then(({ data }) => data);
  }

  static getContactTasks(dto: GenericGetTasksDto) {
    return AtiraApi.post<PageResponseDto<ContactTask[]>>(
      `/task/contact/all`,
      dto,
    ).then(({ data }) => data);
  }

  static getKanbanTasks(dto: GenericGetTasksDto) {
    return AtiraApi.post<PageResponseDto<KanbanTask[]>>(
      `/task/kanban/all`,
      dto,
    ).then(({ data }) => data);
  }
}
