import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Upload, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const uploadProps: UploadProps = {
  name: 'file',
  defaultFileList: [],
  beforeUpload: (file) => false,
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

type ImageUplaodProps = {
  onChange: (file: UploadFile) => void;
  defaultFiles?: UploadFile[];
};

export const ImageUpload: React.FC<ImageUplaodProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Upload
      {...uploadProps}
      maxCount={1}
      defaultFileList={props.defaultFiles}
      onChange={(info) => {
        uploadProps.onChange!(info);
        if (info.file.status !== 'removed') {
          props.onChange(info.file);
        }
      }}
    >
      <Button icon={<UploadOutlined />}>{t('common.upload_file')}</Button>
    </Upload>
  );
};
