import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const taskState = (state: RootState) => state.task;

const selectLeadTasks = createSelector(
  taskState,
  (state) => state.leadTasks || [],
);
const selectLeadTasksLoading = createSelector(
  taskState,
  (state) => state.leadTasksLoading,
);

const selectContactTasks = createSelector(
  taskState,
  (state) => state.contactTasks || [],
);
const selectContactTasksLoading = createSelector(
  taskState,
  (state) => state.contactTasksLoading,
);

const selectKanbanTasks = createSelector(
  taskState,
  (state) => state.kanbanTasks || [],
);
const selectKanbanTasksLoading = createSelector(
  taskState,
  (state) => state.kanbanTasksLoading,
);

export const taskSliceSelectors = {
  selectLeadTasks,
  selectLeadTasksLoading,
  selectContactTasks,
  selectContactTasksLoading,
  selectKanbanTasks,
  selectKanbanTasksLoading,
};
