import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../../components/AtiraDatePicker';
import { Button } from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { Flex } from '../../../../components/Flex';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Entry } from '../../../../model/entry/Entry';
import { CreateLeadTaskDto } from '../../../../model/entry/dto/CreateLeadTaskDto';
import { Lengths } from '../../../../model/shared/enum/Lengths.enum';
import { entryActions } from '../../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { AtiraToast } from '../../../../utils/AtiraToast';

const SubmitButton = styled(Button)`
  height: 2.3rem;
  width: 50%;
`;

interface LeadTaskCreateDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  entry?: Entry | null;
}

export const LeadTaskCreateDrawer: React.FC<LeadTaskCreateDrawerProps> = ({
  isOpen = false,
  onClose,
  entry,
}) => {
  const [createButtonLoading, setCreateButtonLoading] = useState(false);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<CreateLeadTaskDto>({
    defaultValues: {
      name: '',
      description: '',
      reminder: undefined,
      userId,
      done: false,
      deadline: undefined,
      ownerId: userId,
      entryId: entry?._id,
    },
  });

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      setCreateButtonLoading(true);

      const dto = getValues();

      if (dto.reminder) {
        dto.reminder = new Date(dto.reminder).getTime();
      }

      await dispatch(entryActions.createLeadTask(dto)).unwrap();
      await dispatch(
        entryActions.getEntryById({ entryId: entry?._id!, userId }),
      ).unwrap();

      onClose();

      AtiraToast.success(t('kanban.task.create_success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setCreateButtonLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && entry) {
      reset({
        userId,
        ownerId: userId,
        entryId: entry._id,
      });
    }
  }, [isOpen, entry, reset, userId]);

  if (!entry) {
    return null;
  }

  return (
    <Drawer open={isOpen} onClose={onClose} title={t('common.task_create')}>
      <Flex flexDirection="column" gap="m">
        <Controller
          control={control}
          name="name"
          rules={{ required: true, minLength: 3, maxLength: Lengths.NAME }}
          render={({ field: { value, onChange } }) => (
            <Input
              title={t('common.task_name')}
              value={value}
              onChange={onChange}
              maxLength={Lengths.NAME}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <Textarea
              value={value}
              onChange={onChange}
              title={t('common.description')}
              maxLength={Lengths.DESCRIPTION}
            />
          )}
        />

        <Controller
          control={control}
          name="reminder"
          render={({ field: { value, onChange } }) => (
            <AtiraDatePicker
              value={value || null}
              onChange={(date) => onChange(date)}
              title={t('common.remind_date')}
            />
          )}
        />

        <SubmitButton
          disabled={!isValid}
          title={t('common.create')}
          loading={createButtonLoading}
          onClick={handleSubmit(onSubmit)}
        />
      </Flex>
    </Drawer>
  );
};
