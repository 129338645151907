import { Kanban } from '../../model/kanban/Kanban';
import { KanbanCard } from '../../model/kanban/KanbanCard';
import { KanbanColumn } from '../../model/kanban/KanbanColumn';
import { KanbanTask } from '../../model/kanban/KanbanTask';
import { CreateKanbanCardDto } from '../../model/kanban/dto/CreateKanbanCardDto';
import { CreateKanbanTaskDto } from '../../model/kanban/dto/CreateKanbanTaskDto';
import { DeleteKanbanTaskDto } from '../../model/kanban/dto/DeleteKanbanTaskDto';
import { GetKanbanCardByIdDto } from '../../model/kanban/dto/GetKanbanCardByIdDto';
import { GetKanbanCardsByKanbanColumnIdDto } from '../../model/kanban/dto/GetKanbanCardsByKanbanColumnIdDto';
import { GetUserDefaultKanbanDto } from '../../model/kanban/dto/GetUserDefaultKanbanDto';
import { UpdateKanbanCardDto } from '../../model/kanban/dto/UpdateKanbanCardDto';
import { UpdateKanbanColumnDto } from '../../model/kanban/dto/UpdateKanbanColumnDto';
import { UpdateKanbanTaskDto } from '../../model/kanban/dto/UpdateKanbanTaskDto';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { AtiraApi } from '../AxiosClient';

export class KanbanAxios {
  static getUserDefaultKanban(dto: GetUserDefaultKanbanDto) {
    return AtiraApi.post<Kanban>(`/kanban/default`, dto).then(
      ({ data }) => data,
    );
  }

  static getKanbanCardsByKanbanColumnId(
    dto: GetKanbanCardsByKanbanColumnIdDto,
  ) {
    return AtiraApi.post<PageResponseDto<KanbanCard[]>>(
      `/kanban/column/${dto.columnId}`,
      dto,
    ).then(({ data }) => data);
  }

  static createKanbanCard(dto: CreateKanbanCardDto) {
    return AtiraApi.post<void>(`/kanban/card`, dto).then(({ data }) => data);
  }

  static getKanbanCardById(dto: GetKanbanCardByIdDto) {
    return AtiraApi.post<KanbanCard>(`/kanban/card/${dto.cardId}`, dto).then(
      ({ data }) => data,
    );
  }

  static updateKanbanCard(dto: UpdateKanbanCardDto) {
    return AtiraApi.patch<Kanban>(`/kanban/card`, dto).then(({ data }) => data);
  }

  static createKanbanTask(dto: CreateKanbanTaskDto) {
    return AtiraApi.post<void>('/task/kanban/create', dto).then(
      ({ data }) => data,
    );
  }

  static updateKanbanTask(dto: UpdateKanbanTaskDto) {
    return AtiraApi.patch<KanbanTask>(`/task/kanban/${dto.taskId}`, dto).then(
      ({ data }) => data,
    );
  }

  static updateKanbanColumnById(dto: UpdateKanbanColumnDto) {
    return AtiraApi.patch<KanbanColumn>(
      `/kanban/column/${dto.columnId}`,
      dto,
    ).then(({ data }) => data);
  }

  static deleteKanbanTask(dto: DeleteKanbanTaskDto) {
    return AtiraApi.post<void>(`/task/kanban/${dto.taskId}/delete`, dto).then(
      ({ data }) => data,
    );
  }
}
