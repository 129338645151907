import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraColumn } from '../../entries/components/AtiraColumn';

const Title = styled(Text)`
  font-size: 1.25rem;
  font-weight: bold;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.75rem;
  }
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.s};
  margin-inline-start: ${Spacing.xl};
`;

const StyledLi = styled.li`
  font-size: 1rem;
`;

const CallToAction = styled(Link)`
  text-decoration: none;
  font-size: 1rem;
  padding: ${Spacing.s} ${Spacing.m};
  color: ${({ theme }) => theme.sub};
  background-color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.md};
  transition: all 0.3s;
  width: fit-content;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

export const UseCasesServicesTab: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AtiraRow gutter={20}>
      <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={12}>
        <Flex flexDirection="column" gap="l">
          <Title>{t('use_cases.industry.professional_services.title')}</Title>

          <Text>
            {t('use_cases.industry.professional_services.paragraph.1')}
          </Text>

          <StyledUl>
            <StyledLi>
              {t('use_cases.industry.professional_services.li.1')}
            </StyledLi>
            <StyledLi>
              {t('use_cases.industry.professional_services.li.2')}
            </StyledLi>
            <StyledLi>
              {t('use_cases.industry.professional_services.li.3')}
            </StyledLi>
            <StyledLi>
              {t('use_cases.industry.professional_services.li.4')}
            </StyledLi>
            <StyledLi>
              {t('use_cases.industry.professional_services.li.5')}
            </StyledLi>
          </StyledUl>

          <Text>
            {t('use_cases.industry.professional_services.paragraph.2')}
          </Text>
        </Flex>
      </AtiraColumn>

      <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={12}>
        <Flex flexDirection="column" gap="s">
          <AtiraImage src={Images.CasesIndustryServices} />

          <CallToAction to={''}>{t('pricing.section_c2a')}</CallToAction>
        </Flex>
      </AtiraColumn>
    </AtiraRow>
  );
};
