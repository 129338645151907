import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { PublicLayout } from '../PublicLayout';
import { Features } from '../features/Features';
import { LandingPage } from '../landing-page/LandingPage';
import { Login } from '../loginSignup/Login';
import { ForgotPassword } from '../loginSignup/forgot-password/ForgotPassword';
import { Signup } from '../loginSignup/signup/Signup';
import { Pricing } from '../pricing/Pricing';
import { UseCases } from '../use-cases/UseCases';

export const PublicRouter = [
  {
    element: <PublicLayout />,
    errorElement: (
      <div>Something went wrong... Please try refreshing the page</div>
    ),
    children: [
      {
        path: '/home',
        element: (
          <AtiraRouteNotLoggedIn
            component={<LandingPage />}
            redirect="/help/home"
          />
        ),
      },
      {
        path: '/features',
        element: <AtiraRouteNotLoggedIn component={<Features />} />,
      },
      {
        path: '/pricing',
        element: <AtiraRouteNotLoggedIn component={<Pricing />} />,
      },
      {
        path: '/use-cases',
        element: <AtiraRouteNotLoggedIn component={<UseCases />} />,
      },
    ],
  },
  {
    path: '/login',
    element: <AtiraRouteNotLoggedIn component={<Login />} />,
  },
  {
    path: '/signup',
    element: <AtiraRouteNotLoggedIn component={<Signup />} />,
  },
  {
    path: '/forgot-password',
    element: <AtiraRouteNotLoggedIn component={<ForgotPassword />} />,
  },
];
