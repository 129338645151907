import React, { ReactNode, createContext, useContext, useState } from 'react';

import { Entry } from '../../model/entry/Entry';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';

interface EntriesContextType {
  entry: Entry | null;
  setEntry: ATVoidFunction<Entry>;
  //
  task: LeadTask | ContactTask | null;
  setTask: ATVoidFunction<LeadTask | ContactTask>;
  //
  contactTaskUpdateDrawerVisible: boolean;
  setContactTaskUpdateDrawerVisible: ATVoidFunction<boolean>;
  contactTasksReadDrawerVisible: boolean;
  setContactTasksReadDrawerVisible: ATVoidFunction<boolean>;
  leadTasksReadDrawerVisibile: boolean;
  setLeadTasksReadDrawerVisible: ATVoidFunction<boolean>;
  leadTaskUpdateDrawerVisibile: boolean;
  setLeadTaskUpdateDrawerVisible: ATVoidFunction<boolean>;
  leadTaskCreateDrawerVisible: boolean;
  setLeadTaskCreateDrawerVisible: ATVoidFunction<boolean>;
  deleteTaskWarningModalVisible: boolean;
  setDeleteTaskWarningModalVisible: ATVoidFunction<boolean>;
}

const EntriesContext = createContext<EntriesContextType | undefined>(undefined);

export const useEntriesContext = () => {
  const context = useContext(EntriesContext);

  if (!context) {
    throw new Error('useEntriesContext must be used within a EntriesProvider');
  }

  return context;
};

interface EntriesContextProviderProps {
  children: ReactNode;
}

export const EntriesContextProvider: React.FC<EntriesContextProviderProps> = ({
  children,
}) => {
  const [entry, setEntry] = useState<Entry | null>(null);
  //
  const [task, setTask] = useState<LeadTask | ContactTask | null>(null);
  //
  const [contactTaskUpdateDrawerVisible, setContactTaskUpdateDrawerVisible] =
    useState(false);
  const [contactTasksReadDrawerVisible, setContactTasksReadDrawerVisible] =
    useState(false);
  const [leadTasksReadDrawerVisibile, setLeadTasksReadDrawerVisible] =
    useState(false);
  const [leadTaskUpdateDrawerVisibile, setLeadTaskUpdateDrawerVisible] =
    useState(false);
  const [leadTaskCreateDrawerVisible, setLeadTaskCreateDrawerVisible] =
    useState(false);
  const [deleteTaskWarningModalVisible, setDeleteTaskWarningModalVisible] =
    useState(false);

  return (
    <EntriesContext.Provider
      value={{
        entry,
        setEntry,
        //
        task,
        setTask,
        //
        contactTaskUpdateDrawerVisible,
        setContactTaskUpdateDrawerVisible,
        contactTasksReadDrawerVisible,
        setContactTasksReadDrawerVisible,
        leadTasksReadDrawerVisibile,
        setLeadTasksReadDrawerVisible,
        leadTaskUpdateDrawerVisibile,
        setLeadTaskUpdateDrawerVisible,
        leadTaskCreateDrawerVisible,
        setLeadTaskCreateDrawerVisible,
        deleteTaskWarningModalVisible,
        setDeleteTaskWarningModalVisible,
      }}
    >
      {children}
    </EntriesContext.Provider>
  );
};
