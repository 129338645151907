/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { ForgotPasswordEmailDto } from '../../../model/user/dto/ForgotPasswordEmailDto';
import { ForgotPasswordNewPasswordDto } from '../../../model/user/dto/ForgotPasswordNewPasswordDto';
import { ForgotPasswordTokenDto } from '../../../model/user/dto/ForgotPasswordTokenDto';
import { Spacing } from '../../../theme/Spacing';
import { RegisterWrapper } from '../RegisterWrapper';
import { ForgotPasswordEmail } from './ForgotPasswordEmail';
import { ForgotPasswordNewPassword } from './ForgotPasswordNewPassword';
import { ForgotPasswordToken } from './ForgotPasswordToken';

enum FORGOT_PASS_STEPS {
  EMAIL = 'EMAIL',
  TOKEN = 'TOKEN',
  NEW = 'NEW_PASSWORD',
}

const ResponsiveFlex = styled(Flex)`
  @media (min-width: 360px) and (max-width: 990px) {
    padding: ${Spacing.m};
  }
`;

export const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState<FORGOT_PASS_STEPS>(FORGOT_PASS_STEPS.EMAIL);
  const formMethods = useForm<
    ForgotPasswordEmailDto &
      ForgotPasswordTokenDto &
      ForgotPasswordNewPasswordDto
  >({
    defaultValues: { email: '', token: '', newPassword: '' },
  });

  return (
    <RegisterWrapper>
      <ResponsiveFlex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
      >
        <FormProvider {...formMethods}>
          {step === FORGOT_PASS_STEPS.EMAIL ? (
            <ForgotPasswordEmail
              updateStep={() => setStep(FORGOT_PASS_STEPS.TOKEN)}
            />
          ) : step === FORGOT_PASS_STEPS.TOKEN ? (
            <ForgotPasswordToken
              updateStep={() => setStep(FORGOT_PASS_STEPS.NEW)}
            />
          ) : (
            <ForgotPasswordNewPassword />
          )}
        </FormProvider>
      </ResponsiveFlex>
    </RegisterWrapper>
  );
};
