import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../components/AtiraIcon';
import { Button } from '../components/Button';
import { Drawer } from '../components/Drawer';
import { Flex } from '../components/Flex';
import i18n, { AppLangs } from '../i18n';
import { Spacing } from '../theme/Spacing';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-title,
  svg {
    color: ${({ theme }) => theme.sub};
    font-size: 1.5rem;
  }

  .ant-drawer-header {
    border-bottom: ${({ theme }) => `1px solid ${theme.sub}`};
  }

  background-image: ${({ theme }) =>
    `linear-gradient(${theme.main}, ${theme.lightMain})`} !important;
  opacity: 0.9;
`;

const LinkButton = styled(Button)`
  color: ${({ theme }) => theme.sub};
  font-size: 1.3rem;
  display: flex;
  gap: ${Spacing.m};
  align-items: center;
  transition: all 0.3s;
  margin: 0;
  padding: 0;

  &:hover {
    gap: ${Spacing.l};
  }
`;

interface PublicSidebarProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const PublicSidebar: React.FC<PublicSidebarProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <StyledDrawer title={t('app.name')} open={isOpen} onClose={onClose}>
      <Flex flexDirection="column" gap="m">
        <LinkButton onClick={() => onNavigate('/home')}>
          <AtiraIcon
            icon={i18n.language === AppLangs.AR ? faAngleLeft : faAngleRight}
            color="main"
            size="1x"
          />

          {t('public_header.home')}
        </LinkButton>

        <LinkButton onClick={() => onNavigate('/pricing')}>
          <AtiraIcon
            icon={i18n.language === AppLangs.AR ? faAngleLeft : faAngleRight}
            color="main"
            size="1x"
          />

          {t('public_header.pricing')}
        </LinkButton>

        <LinkButton onClick={() => onNavigate('/features')}>
          <AtiraIcon
            icon={i18n.language === AppLangs.AR ? faAngleLeft : faAngleRight}
            color="main"
            size="1x"
          />

          {t('public_header.features')}
        </LinkButton>

        <LinkButton onClick={() => onNavigate('/use-cases')}>
          <AtiraIcon
            icon={i18n.language === AppLangs.AR ? faAngleLeft : faAngleRight}
            color="main"
            size="1x"
          />

          {t('public_header.use_cases')}
        </LinkButton>
      </Flex>
    </StyledDrawer>
  );
};
