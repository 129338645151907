import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Tooltip } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { styled } from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { KanbanTask } from '../../../../model/kanban/KanbanTask';
import { Spacing } from '../../../../theme/Spacing';
import { standardDate } from '../../../../utils/Date';
import { useKanbanContext } from '../../kanban-context';

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  border-radius: 0.3rem;
  width: 7rem;
`;

const DeleteButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.red};
`;

type KanbanTaskDetailsProps = {
  kanbanTask: KanbanTask;
};

export const KanbanTaskDetails: React.FC<KanbanTaskDetailsProps> = ({
  kanbanTask,
}) => {
  const {
    setTaskUpdateDrawerVisible,
    setTask,
    setTasksReadDrawerVisible,
    setTaskDeleteModalVisible,
  } = useKanbanContext();

  const onOpenKanbanTaskUpdateDrawer = () => {
    setTask(kanbanTask);
    setTasksReadDrawerVisible(false);
    setTaskUpdateDrawerVisible(true);
  };

  const onDeleteTask = () => {
    setTask(kanbanTask);
    setTaskDeleteModalVisible(true);
  };

  return (
    <Flex flexDirection="column" gap="m" flex={1}>
      <StyledFlex>
        <BoldText>{t('common.task_name')}:</BoldText>

        <ValueText>{kanbanTask?.name}</ValueText>
      </StyledFlex>

      {kanbanTask?.description ? (
        <StyledFlex>
          <BoldText>{t('common.description')}:</BoldText>

          <ValueText>{kanbanTask.description}</ValueText>
        </StyledFlex>
      ) : null}

      {kanbanTask?.reminder ? (
        <StyledFlex>
          <BoldText>{t('kanban.task.date.title')}</BoldText>

          <ValueText>{standardDate(kanbanTask.reminder)}</ValueText>
        </StyledFlex>
      ) : null}

      <Flex justifyContent="space-between" alignItems="center">
        <StyledButton onClick={onOpenKanbanTaskUpdateDrawer}>
          {t('common.edit')}
        </StyledButton>

        <Tooltip title={t('common.delete')}>
          <DeleteButton icon={faTrash} iconWidth="2x" onClick={onDeleteTask} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
