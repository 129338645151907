import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Checkbox, CheckboxOptionType, GetProp, Tooltip } from 'antd';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraCheckBoxGroup } from '../../../../components/AtiraCheckBoxGroup';
import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Spinner } from '../../../../components/Spinner';
import { Text } from '../../../../components/Text';
import { PageMeta } from '../../../../model/meta/PageMeta';
import { LeadTask } from '../../../../model/task/LeadTask';
import { TaskKind } from '../../../../model/task/types/TaskKind.eum';
import { entryActions } from '../../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { taskActions } from '../../../../redux/task/task.slice';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { Spacing } from '../../../../theme/Spacing';
import { AtiraToast } from '../../../../utils/AtiraToast';
import { standardDate } from '../../../../utils/Date';
import { useEntriesContext } from '../../entries-context';

const getCheckedValues = (contactAttempts: number) => {
  switch (contactAttempts) {
    case 1:
      return ['0'];
    case 2:
      return ['0', '1'];
    case 3:
      return ['0', '1', '2'];

    case 0:
    default:
      return [];
  }
};

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const UpdateButton = styled(Button)`
  height: 2.5rem;
  width: 7rem;
`;

const StyledAtiraCheckBoxGroup = styled(AtiraCheckBoxGroup)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const DeleteButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.red};
`;

interface LeadTaskDetailsProps {
  leadTask: LeadTask;
  onSetTask: ATVoidFunction<LeadTask>;
  onSetReadDrawerVisible: ATVoidFunction<boolean>;
  onSetUpdateDrawerVisible: ATVoidFunction<boolean>;
  onSetDeleteTaskWarningModalVisible: ATVoidFunction<boolean>;
}

export const LeadTaskDetails: React.FC<LeadTaskDetailsProps> = ({
  leadTask,
  onSetTask,
  onSetReadDrawerVisible,
  onSetUpdateDrawerVisible,
  onSetDeleteTaskWarningModalVisible,
}) => {
  const [checkLoading, setCheckLoading] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState<string[]>([]);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { setEntry } = useEntriesContext();

  const onOpenUpdateDrawer = () => {
    onSetReadDrawerVisible(false);
    onSetTask(leadTask);
    onSetUpdateDrawerVisible(true);
  };

  const onDeleteTask = () => {
    onSetTask(leadTask);
    onSetDeleteTaskWarningModalVisible(true);
  };

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const getLeadTaskAttemptsOptions = () => {
    return [
      {
        label: (
          <Flex gap="s" alignItems="center">
            <Text
              style={{ whiteSpace: 'nowrap' }}
            >{`${t('common.attempt')} 1`}</Text>
            {checkLoading && checkedBoxes.length === 1 ? (
              <Spinner color="main" size="1rem" />
            ) : null}
          </Flex>
        ),
        value: '0',
        disabled: checkedBoxes.includes('0'),
      },
      {
        label: (
          <Flex gap="s" alignItems="center">
            <Text
              style={{ whiteSpace: 'nowrap' }}
            >{`${t('common.attempt')} 2`}</Text>
            {checkLoading && checkedBoxes.length === 2 ? (
              <Spinner color="main" size="1rem" />
            ) : null}
          </Flex>
        ),
        value: '1',
        disabled:
          checkedBoxes.includes('1') ||
          !checkedBoxes.includes('0') ||
          checkLoading,
      },
      {
        label: (
          <Flex gap="s" alignItems="center">
            <Text
              style={{ whiteSpace: 'nowrap' }}
            >{`${t('common.attempt')} 3`}</Text>
            {checkLoading && checkedBoxes.length === 3 ? (
              <Spinner color="main" size="1rem" />
            ) : null}
          </Flex>
        ),
        value: '2',
        disabled:
          checkedBoxes.includes('2') ||
          !(checkedBoxes.includes('0') && checkedBoxes.includes('1')) ||
          checkLoading,
      },
    ] as CheckboxOptionType[];
  };

  const incrementLeadTaskAttempt: GetProp<
    typeof Checkbox.Group,
    'onChange'
  > = async (checkedValues) => {
    try {
      setCheckLoading(true);
      setCheckedBoxes((prev) => [...prev, `${checkedValues.length - 1}`]);

      await dispatch(
        entryActions.incrementLeadTaskAttempt({
          taskId: leadTask._id!,
          userId,
        }),
      ).unwrap();

      if (pathname.includes('/task')) {
        if (leadTask.kind === TaskKind.CONTACT) {
          await dispatch(
            taskActions.getContactTasks({ userId, meta: PageMeta.create() }),
          ).unwrap();
        } else if (leadTask.kind === TaskKind.KANBAN) {
          await dispatch(
            taskActions.getKanbanTasks({ userId, meta: PageMeta.create() }),
          ).unwrap();
        } else if (leadTask.kind === TaskKind.LEAD) {
          await dispatch(
            taskActions.getLeadTasks({ userId, meta: PageMeta.create() }),
          ).unwrap();
        }
      }

      const updatedEntry = await dispatch(
        entryActions.getEntryById({ entryId: leadTask.entryId!, userId }),
      ).unwrap();

      setEntry(updatedEntry);

      AtiraToast.success(t('entries.contact_attempt.increment.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
      setCheckedBoxes((prev) => prev.slice(0, -1));
    } finally {
      setCheckLoading(false);
    }
  };

  useEffect(() => {
    setCheckedBoxes(getCheckedValues(leadTask?.contactAttempts!));
  }, [leadTask?.contactAttempts]);

  return (
    <Flex flex={1} flexDirection="column" gap="m">
      <StyledFlex>
        <BoldText>{t('task.read.name')}</BoldText>
        <ValueText>{leadTask.name}</ValueText>
      </StyledFlex>

      {leadTask.reminder ? (
        <StyledFlex>
          <Flex alignItems="center" gap="s">
            <BoldText>{t('common.remind_date')}: </BoldText>
          </Flex>

          <ValueText>{standardDate(leadTask.reminder)}</ValueText>
        </StyledFlex>
      ) : null}

      {leadTask.description ? (
        <Flex gap="s" flexWrap="wrap">
          <BoldText>{t('common.description')}:</BoldText>
          <Flex>
            <Text wordBreak="break-word">{leadTask.description}</Text>
          </Flex>
        </Flex>
      ) : null}

      {!isNil(leadTask.contactAttempts) ? (
        <Flex flexDirection="column" gap="s">
          <StyledFlex>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} size="xs" color="main" />

              <BoldText>{t('entries.details.contact_attempts')}: </BoldText>
            </Flex>

            <Text fontSize="s" color="gray">
              {t('entries.details.contact_attempts.description')}
            </Text>
          </StyledFlex>

          <StyledAtiraCheckBoxGroup
            onChange={incrementLeadTaskAttempt}
            options={getLeadTaskAttemptsOptions()}
            value={checkedBoxes}
            fontSize="1rem"
          />
        </Flex>
      ) : null}

      <Flex justifyContent="space-between" alignItems="center">
        <UpdateButton onClick={onOpenUpdateDrawer}>
          {t('common.edit')}
        </UpdateButton>

        <Tooltip title={t('common.delete')}>
          <DeleteButton icon={faTrash} iconWidth="2x" onClick={onDeleteTask} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
