import { Checkbox, CheckboxOptionType, GetProp } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../theme/Spacing';

const StyledCheckbox = styled(Checkbox.Group)<StyledAtiraBoxProps>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${Spacing.xxl};

  .ant-checkbox .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
  }

  .ant-checkbox-wrapper {
    white-space: nowrap;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.gray};
  }

  .ant-checkbox + span {
    font-size: ${({ fontSize }) => fontSize || '1.5rem'};
    width: 5rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -70%);
  }

  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.lightMain} !important;
    opacity: 0.7;
  }

  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after {
    border-inline-end: 2px solid ${({ theme }) => theme.white};
    border-bottom: 2px solid ${({ theme }) => theme.white};
  }
`;

type AtiraCheckBoxProps = {
  options: CheckboxOptionType[];
  value: string[];
  onChange: GetProp<typeof Checkbox.Group, 'onChange'>;
  style?: React.CSSProperties;
  fontSize?: string;
};

type StyledAtiraBoxProps = {
  fontSize?: string;
};

export const AtiraCheckBoxGroup: React.FC<AtiraCheckBoxProps> = (props) => {
  return <StyledCheckbox {...props} />;
};
