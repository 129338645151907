import { createSlice } from '@reduxjs/toolkit';

import { TaskAxios } from '../../axios/task/task.axios';
import { KanbanTask } from '../../model/kanban/KanbanTask';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';
import { GenericGetTasksDto } from '../../model/task/dto/GenericGetTasksDto';
import { AtiraThunk } from '../AtiraThunk';

interface TaskReducer {
  leadTasksLoading: boolean;
  leadTasks: LeadTask[];
  contactTasksLoading: boolean;
  contactTasks: ContactTask[];
  kanbanTasksLoading: boolean;
  kanbanTasks: KanbanTask[];
}

const initialState = Object.freeze<TaskReducer>({
  leadTasksLoading: false,
  leadTasks: [],
  contactTasksLoading: false,
  contactTasks: [],
  kanbanTasksLoading: false,
  kanbanTasks: [],
});

const getLeadTasks = AtiraThunk<
  PageResponseDto<LeadTask[]>,
  GenericGetTasksDto
>(`/task/lead`, (dto) => TaskAxios.getLeadTasks(dto));

const getContactTasks = AtiraThunk<
  PageResponseDto<ContactTask[]>,
  GenericGetTasksDto
>(`/task/contact`, (dto) => TaskAxios.getContactTasks(dto));

const getKanbanTasks = AtiraThunk<
  PageResponseDto<KanbanTask[]>,
  GenericGetTasksDto
>(`/task/kanban`, (dto) => TaskAxios.getKanbanTasks(dto));

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getLeadTasks.pending, (state) => {
      state.leadTasksLoading = true;
    });
    addCase(getLeadTasks.fulfilled, (state, action) => {
      state.leadTasks = action.payload.data;
      state.leadTasksLoading = false;
    });
    addCase(getLeadTasks.rejected, (state) => {
      state.leadTasksLoading = false;
    });

    addCase(getContactTasks.pending, (state) => {
      state.contactTasksLoading = true;
    });
    addCase(getContactTasks.fulfilled, (state, action) => {
      state.contactTasks = action.payload.data;
    });
    addCase(getContactTasks.rejected, (state) => {
      state.contactTasksLoading = false;
    });

    addCase(getKanbanTasks.pending, (state) => {
      state.kanbanTasksLoading = true;
    });
    addCase(getKanbanTasks.fulfilled, (state, action) => {
      state.kanbanTasks = action.payload.data;
    });
    addCase(getKanbanTasks.rejected, (state) => {
      state.kanbanTasksLoading = false;
    });
  },
});

export const taskActions = {
  getLeadTasks,
  getKanbanTasks,
  getContactTasks,
};

export default taskSlice.reducer;
