import { AtiraRoute } from '../AtiraRoute';
import { LandingPage } from '../landing-page/LandingPage';
import { ProfileEdit } from '../profile/ProfileEdit';
import { Subscription } from '../subscription/Subscription';

export const PrivateHelpRouter = [
  {
    path: '/help/home',
    element: <AtiraRoute component={<LandingPage />} />,
  },
  {
    path: '/profile',
    element: <AtiraRoute component={<ProfileEdit />} />,
  },
  {
    path: '/subscription',
    element: <AtiraRoute component={<Subscription />} />,
  },
];
