import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../assets';
import { AtiraIcon } from '../../components/AtiraIcon';
import { AtiraRow } from '../../components/AtiraRow';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { GenericGetStartedSection } from '../../components/public/GenericGetStartedSection';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraColumn } from '../entries/components/AtiraColumn';

const HeroSection = styled(Flex)`
  background-image: url(${Images.FeaturesPageBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: auto;

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    height: 90vh;
  }
`;

const ResponsiveFlex = styled(Flex)`
  @media (min-width: ${Breakpoints.TABLET}) {
    padding: ${Spacing.xxl};
  }
`;

const GreySection = styled(Flex)`
  background-color: ${({ theme }) => theme.sub};
  padding: ${Spacing.l};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.xxl};
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    padding-inline: calc(${Spacing.xxl}*1.5);
  }
`;

const WhiteSection = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  padding: ${Spacing.l};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: ${Spacing.xxl};
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    padding-inline: calc(${Spacing.xxl}*1.5);
  }
`;

const MainTitles = styled(Text)`
  font-size: 1.75rem;
  font-weight: bold;
  color: ${({ theme }) => theme.main};
`;

const BoldText = styled.span`
  font-size: 1.25rem;
  font-weight: bolder;
  white-space: nowrap;
`;

const TitleDescription = styled(Text)`
  gap: ${Spacing.s};
  font-size: 1.25rem;
  word-break: break-word;
  white-space: normal;
`;

export const Features: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" height={'100%'}>
      <HeroSection>
        <ResponsiveFlex flexDirection="column" justifyContent="center">
          <AtiraRow justify={'center'} align={'middle'} gutter={[8, 32]}>
            <AtiraColumn sm={24} md={24} lg={24} xl={24}>
              <Text
                fontSize="xxxl"
                align="center"
                color="main"
                fontWeight={'bold'}
              >
                {t('features.hero_section.main_title')}
              </Text>
            </AtiraColumn>
            <AtiraColumn sm={24} md={24} lg={24} xl={23}>
              <Text fontSize="xl" align="center">
                {t('features.hero_section.des')}
              </Text>
            </AtiraColumn>
            <AtiraColumn sm={24} md={24} lg={24} xl={12}>
              <Text
                fontSize="l"
                align="center"
                color="main"
                fontWeight={'bold'}
              >
                {t('features.hero_section.title')}
              </Text>
            </AtiraColumn>
          </AtiraRow>
        </ResponsiveFlex>
      </HeroSection>

      <GreySection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.first_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.first_section.des')} `}
                  <BoldText>{t('common.atira_space_crm')}</BoldText>
                  {` ${t('features.first_section.des_you_can')}`}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.first_section.item_title_1')}
                    </BoldText>
                    {t('features.first_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.first_section.item_title_2')}
                    </BoldText>
                    {t('features.first_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.first_section.item_title_3')}
                    </BoldText>
                    {t('features.first_section.item_des_3')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.first_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>

          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageContactManagement}
            />
          </AtiraColumn>
        </AtiraRow>
      </GreySection>

      <WhiteSection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 24 }}
            md={{ order: 2, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <AtiraImage width="100%" src={Images.FeaturesPageSalesPipeline} />
          </AtiraColumn>

          <AtiraColumn
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 1, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.second_section.title')}</MainTitles>
                <Text fontSize="xm" style={{ whiteSpace: 'normal' }}>
                  {`${t('features.second_section.des')} `}
                  <BoldText>{t('common.sales_pipeline')}</BoldText>
                  {` ${t('features.second_section.des_allowing_you_to')}`}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.second_section.item_title_1')}
                    </BoldText>
                    {t('features.second_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.second_section.item_title_2')}
                    </BoldText>
                    {t('features.second_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.second_section.item_title_3')}
                    </BoldText>
                    {t('features.second_section.item_des_3')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.second_section.item_title_4')}
                    </BoldText>
                    {t('features.second_section.item_des_4')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.second_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>
        </AtiraRow>
      </WhiteSection>

      <GreySection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.third_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.third_section.des')} `}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.third_section.item_title_1')}
                    </BoldText>
                    {t('features.third_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.third_section.item_title_2')}
                    </BoldText>
                    {t('features.third_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.third_section.item_title_3')}
                    </BoldText>
                    {t('features.third_section.item_des_3')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.third_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>

          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageTaskWorkflowAutomation}
            />
          </AtiraColumn>
        </AtiraRow>
      </GreySection>

      <WhiteSection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 24 }}
            md={{ order: 2, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageReportingAnalytics}
            />
          </AtiraColumn>

          <AtiraColumn
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 1, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.fourth_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.fourth_section.des')} `}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fourth_section.item_title_1')}
                    </BoldText>
                    {t('features.fourth_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fourth_section.item_title_2')}
                    </BoldText>
                    {t('features.fourth_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fourth_section.item_title_3')}
                    </BoldText>
                    {t('features.fourth_section.item_des_3')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fourth_section.item_title_4')}
                    </BoldText>
                    {t('features.fourth_section.item_des_4')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.fourth_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>
        </AtiraRow>
      </WhiteSection>

      <GreySection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.fifth_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.fifth_section.des')} `}
                  <BoldText>{t('common.marketing_automation')}</BoldText>
                  {` ${t('features.fifth_section.des_to_nurture_leads')}`}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fifth_section.item_title_1')}
                    </BoldText>
                    {t('features.fifth_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fifth_section.item_title_2')}
                    </BoldText>
                    {t('features.fifth_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fifth_section.item_title_3')}
                    </BoldText>
                    {t('features.fifth_section.item_des_3')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.fifth_section.item_title_4')}
                    </BoldText>
                    {t('features.fifth_section.item_des_4')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.fifth_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>

          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageEmailMarketingAutomation}
            />
          </AtiraColumn>
        </AtiraRow>
      </GreySection>

      <WhiteSection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 24 }}
            md={{ order: 2, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageCalendarAppointmentScheduling}
            />
          </AtiraColumn>

          <AtiraColumn
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 1, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.sixth_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.sixth_section.des')} `}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.sixth_section.item_title_1')}
                    </BoldText>
                    {t('features.sixth_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.sixth_section.item_title_2')}
                    </BoldText>
                    {t('features.sixth_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.sixth_section.item_title_3')}
                    </BoldText>
                    {t('features.sixth_section.item_des_3')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.sixth_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>
        </AtiraRow>
      </WhiteSection>

      <GreySection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.seventh_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.seventh_section.des')} `}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.seventh_section.item_title_1')}
                    </BoldText>
                    {t('features.seventh_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.seventh_section.item_title_2')}
                    </BoldText>
                    {t('features.seventh_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.seventh_section.item_title_3')}
                    </BoldText>
                    {t('features.seventh_section.item_des_3')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.seventh_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>

          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageLeadManagementScoring}
            />
          </AtiraColumn>
        </AtiraRow>
      </GreySection>

      <WhiteSection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 24 }}
            md={{ order: 2, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageCustomizationFlexibility}
            />
          </AtiraColumn>

          <AtiraColumn
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 1, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.eighth_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.eighth_section.des')} `}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.eighth_section.item_title_1')}
                    </BoldText>
                    {t('features.eighth_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.eighth_section.item_title_2')}
                    </BoldText>
                    {t('features.eighth_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.eighth_section.item_title_3')}
                    </BoldText>
                    {t('features.eighth_section.item_des_3')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.eighth_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>
        </AtiraRow>
      </WhiteSection>

      <GreySection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.ninth_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.ninth_section.des')} `}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.ninth_section.item_title_1')}
                    </BoldText>
                    {t('features.ninth_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.ninth_section.item_title_2')}
                    </BoldText>
                    {t('features.ninth_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.ninth_section.item_title_3')}
                    </BoldText>
                    {t('features.ninth_section.item_des_3')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.ninth_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>

          <AtiraColumn sm={24} md={24} lg={12} xl={12}>
            <AtiraImage width="100%" src={Images.FeaturesPageMobileCRMAccess} />
          </AtiraColumn>
        </AtiraRow>
      </GreySection>

      <WhiteSection>
        <AtiraRow justify="space-between" align="middle">
          <AtiraColumn
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 24 }}
            md={{ order: 2, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <AtiraImage
              width="100%"
              src={Images.FeaturesPageIntegrationCapabilities}
            />
          </AtiraColumn>

          <AtiraColumn
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 1, span: 24 }}
            lg={{ order: 1, span: 12 }}
            xl={{ order: 1, span: 12 }}
          >
            <ResponsiveFlex flexDirection="column">
              <Flex flexDirection="column" gap="xxl">
                <MainTitles>{t('features.tenth_section.title')}</MainTitles>
                <Text fontSize="xm">
                  {`${t('features.tenth_section.des')} `}
                </Text>
              </Flex>

              <Flex flexDirection="column" padding="m" gap="s">
                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.tenth_section.item_title_1')}
                    </BoldText>
                    {t('features.tenth_section.item_des_1')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.tenth_section.item_title_2')}
                    </BoldText>
                    {t('features.tenth_section.item_des_2')}
                  </TitleDescription>
                </Flex>

                <Flex gap="s">
                  <AtiraIcon
                    icon={faCircle}
                    size="2xs"
                    style={{ marginTop: '0.5rem' }}
                  />
                  <TitleDescription>
                    <BoldText>
                      {t('features.tenth_section.item_title_3')}
                    </BoldText>
                    {t('features.tenth_section.item_des_3')}
                  </TitleDescription>
                </Flex>
              </Flex>
              <Flex>
                <TitleDescription>
                  <BoldText>{t('common.benefit')}</BoldText>
                  {t('features.tenth_section.benefit')}
                </TitleDescription>
              </Flex>
            </ResponsiveFlex>
          </AtiraColumn>
        </AtiraRow>
      </WhiteSection>

      <GenericGetStartedSection />
    </Flex>
  );
};
