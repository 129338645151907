import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { CollapseProps, Tooltip } from 'antd';
import { isToday, isTomorrow } from 'date-fns';
import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../../ThemeContext';
import { AtiraCollapse } from '../../../../components/AtiraCollapse';
import { AtiraEmpty } from '../../../../components/AtiraEmpty';
import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { ContactTaskPriority } from '../../../../model/entry/types/ContactTaskPriority.enum';
import { ContactTask } from '../../../../model/task/ContactTask';
import { ThemeType } from '../../../../theme/Theme';
import { ContactTaskDetails } from './ContactTaskDetails';

interface ContactTasksReadDrawerProps {
  tasks: ContactTask[] | undefined;
  isOpen: boolean;
  onClose: VoidFunction;
}

const getDeadlineDateAsText = (date: Date | undefined, t: any): string => {
  const now = new Date();

  if (!date) {
    return t('common.no_deadline_selected');
  }

  const deadline = new Date(date);

  if (isToday(deadline)) {
    return t('common.today');
  }
  if (isTomorrow(deadline)) {
    return t('common.tomorrow');
  }
  if (deadline < now) {
    return t('common.out_due');
  }

  return deadline.toLocaleDateString();
};

const getTaskPriorityTextColor = (taskPriority?: ContactTaskPriority) => {
  switch (taskPriority) {
    case ContactTaskPriority.LOW:
      return 'green';
    case ContactTaskPriority.MEDIUM:
      return 'orange';
    case ContactTaskPriority.HIGH:
      return 'red';
  }
};

const getButtonBackgroundColor = (
  ContactTask: ContactTask,
): keyof ThemeType => {
  const now = new Date();

  if (!ContactTask.deadline) {
    return 'gray';
  }

  const deadline = new Date(ContactTask.deadline);

  if (isToday(deadline)) {
    return 'orange';
  } else if (deadline < now) {
    return 'red';
  } else if (isTomorrow(deadline)) {
    return 'green';
  }

  return 'green';
};

export const ContactTasksReadDrawer: React.FC<ContactTasksReadDrawerProps> = ({
  tasks,
  isOpen = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const { theme } = useTheme();

  const items: CollapseProps['items'] = useMemo(() => {
    const panelStyle: CSSProperties = {
      borderBottom: '1px solid black',
      backgroundColor: theme.sub,
      border: '0',
    };

    if (tasks?.length) {
      return tasks.map((task, index) => ({
        key: `${index + 1}`,
        label: (
          <Flex justifyContent="space-between">
            <Text>{task?.name}</Text>
            <Flex gap="s" alignItems="center">
              <Tooltip title={t('common.priority')}>
                <Text
                  style={{
                    color: getTaskPriorityTextColor(task?.priority),
                  }}
                >
                  <AtiraIcon icon={faCircle} size="1x" />
                </Text>
              </Tooltip>
              <Button
                margin="0"
                style={{
                  backgroundColor: theme[getButtonBackgroundColor(task)],
                }}
              >
                {getDeadlineDateAsText(
                  task?.deadline ? new Date(task.deadline) : undefined,
                  t,
                )}
              </Button>
            </Flex>
          </Flex>
        ),
        children: task ? <ContactTaskDetails contactTask={task} /> : null,
        style: panelStyle,
      }));
    }

    return [];
  }, [t, tasks, theme]);

  if (!tasks) {
    return null;
  }

  return (
    <Drawer
      title={t('tasks.read.drawer.title')}
      open={isOpen}
      onClose={onClose}
      width={'30rem'}
    >
      {items.length ? (
        <AtiraCollapse items={items} accordion />
      ) : (
        <AtiraEmpty description={t('entries.contact_tasks.empty')} />
      )}
    </Drawer>
  );
};
