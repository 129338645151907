import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { Rounded } from '../../../../theme/Rounded';
import { Shadow } from '../../../../theme/Shadow';
import { Spacing } from '../../../../theme/Spacing';
import { WidgetCustomizeDrawer } from './WidgetCustomizeDrawer';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.darkSub};
  border-radius: ${Rounded.lg};
  width: 100%;
  height: 14rem;
  padding: 1rem;
  box-shadow: ${Shadow.SM};
`;

const Wrapper = styled(Flex)`
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: ${Spacing.s};
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  padding: 0.2rem 0;
`;

export const WidgetCreateWidget: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Flex alignItems="center" justifyContent="space-between">
        <Title fontSize="l">{t('widget.more')}</Title>
      </Flex>

      <Flex>
        <Container>
          <Flex justifyContent="center" alignItems="center" width={'100%'}>
            <Flex onClick={() => setOpen(true)} cursor="pointer">
              <AtiraIcon icon={faPlus} size="6x" color="darkerSub" />
            </Flex>
          </Flex>
        </Container>
        <WidgetCustomizeDrawer open={open} onClose={() => setOpen(false)} />
      </Flex>
    </Wrapper>
  );
};
