import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faGauge } from '@fortawesome/free-solid-svg-icons/faGauge';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faTableList } from '@fortawesome/free-solid-svg-icons/faTableList';
import { Menu, MenuProps } from 'antd';
import { TFunction } from 'i18next';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { SideBarRoutes } from '../routes/types/SideBarRoutes.enum';
import { Theme } from '../theme/Theme';
import { AtiraIcon } from './AtiraIcon';
import { AtriaTourClasses } from './tour/AtiraTourClasses.enum';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

const getMenuItems = (config: {
  t: TFunction;
  navigate: NavigateFunction;
  collapsed: boolean;
}): Required<MenuProps>['items'] => {
  const { t, navigate, collapsed } = config;

  const genericItemStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    margin: '0',
    fontSize: '1.3rem',
    color: Theme.sub,
    borderRadius: '0',
    height: '3.3rem',
    width: '100%',
  };

  return [
    {
      key: SideBarRoutes.DASHBOARD,
      icon: <AtiraIcon icon={faGauge} />,
      label: !collapsed ? t('side_menu.dashboard') : null,
      onClick: () => navigate(SideBarRoutes.DASHBOARD),
      style: genericItemStyle,
      className: AtriaTourClasses.MENU_DASHBOARD,
    },
    {
      key: SideBarRoutes.FORMS,
      icon: <AtiraIcon icon={faTableList} />,
      label: !collapsed ? t('side_menu.forms') : null,
      onClick: () => navigate(SideBarRoutes.FORMS),
      style: genericItemStyle,
      className: AtriaTourClasses.MENU_FORMS,
    },
    {
      key: SideBarRoutes.ENTRIES,
      icon: <AtiraIcon icon={faTable} />,
      label: !collapsed ? t('side_menu.entries') : null,
      onClick: () => navigate(SideBarRoutes.ENTRIES),
      style: genericItemStyle,
      className: AtriaTourClasses.MENU_ENTRIES,
    },
    {
      key: SideBarRoutes.DEALS,
      icon: <AtiraIcon icon={faHandshake} />,
      label: !collapsed ? t('side_menu.deals') : null,
      onClick: () => navigate(SideBarRoutes.DEALS),
      style: genericItemStyle,
      className: AtriaTourClasses.MENU_DEALS,
    },
    {
      key: SideBarRoutes.REPORTS,
      icon: <AtiraIcon icon={faChartPie} />,
      label: !collapsed ? t('side_menu.reports') : null,
      onClick: () => navigate(SideBarRoutes.REPORTS),
      style: genericItemStyle,
      className: AtriaTourClasses.MENU_REPORTS,
    },
    {
      key: SideBarRoutes.TASKS,
      icon: <AtiraIcon icon={faCheck} />,
      label: !collapsed ? t('side_menu.tasks') : null,
      onClick: () => navigate(SideBarRoutes.TASKS),
      style: genericItemStyle,
      className: AtriaTourClasses.MENU_DEALS,
    },
  ];
};

export const SideMenu: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getSelectedKey = useMemo(() => {
    switch (true) {
      case pathname.startsWith(SideBarRoutes.FORMS):
        return SideBarRoutes.FORMS;
      default:
        return pathname;
    }
  }, [pathname]);

  return (
    <Menu
      style={{
        position: 'relative',
        top: '3.5rem',
        backgroundColor: 'transparent',
        padding: '0',
      }}
      mode="inline"
      theme="dark"
      items={getMenuItems({ t, navigate, collapsed })}
      selectedKeys={[getSelectedKey]}
    />
  );
};
