import { Collapse, CollapseProps } from 'antd';
import React from 'react';

interface AtiraCollapseProps {
  items: CollapseProps['items'];
  defaultActiveKey?: string[];
  bordered?: boolean;
  accordion?: boolean;
}

export const AtiraCollapse: React.FC<AtiraCollapseProps> = ({
  items = [],
  defaultActiveKey = ['1'],
  bordered,
  accordion,
}) => {
  return (
    <Collapse
      style={{ overflow: 'hidden' }}
      items={items}
      defaultActiveKey={defaultActiveKey}
      bordered={bordered}
      accordion={accordion}
    />
  );
};
