import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import isNumber from 'lodash/isNumber';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { KanbanTask } from '../../../model/kanban/KanbanTask';
import { ContactTask } from '../../../model/task/ContactTask';
import { LeadTask } from '../../../model/task/LeadTask';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { truncateString } from '../../../utils/String';

const StyledActionButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

type Task =
  | Omit<LeadTask, 'entryId'>
  | Omit<ContactTask, 'entryId'>
  | Omit<KanbanTask, 'kanbanCardId'>;
type TaskATVoidFunction = ATVoidFunction<Task>;
type FieldFunctions = {
  deleteTask: TaskATVoidFunction;
  updateTask: TaskATVoidFunction;
  previewTask: TaskATVoidFunction;
  t: TFunction;
};

// Define a type that includes all keys of LeadTask plus the 'action' key
type LeadTaskKeys = keyof LeadTask | 'actions';

const TableColumnKeys: LeadTaskKeys[] = [
  'actions',
  'name',
  'reminder',
  'description',
  // 'done',
  'contactAttempts',
  'createdAt',
];

// This will throw a TypeScript error if the keys array does not match the keys of LeadTask plus 'action'
// @ts-ignore
const _ensureAllKeys: Record<LeadTaskKeys, true> = {
  actions: true,
  name: true,
  reminder: true,
  description: true,
  // done: true,
  contactAttempts: true,
  createdAt: true,
};

const getTruncatedString = (value: string, length: number) => {
  if (value?.length >= length) {
    return <Tooltip title={value}>{truncateString(value, length)}</Tooltip>;
  }

  return value;
};

const getFieldWidth = (field: string) => {
  switch (field) {
    case 'message':
    case 'phone':
    case 'description':
    case 'form':
      return '10rem';

    default:
      return '7rem';
  }
};

const getFieldInfo = (column: LeadTaskKeys, functions: FieldFunctions) => {
  const { t, deleteTask, updateTask, previewTask } = functions;
  const imgWidth = '20';

  switch (column) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'actions',
        render: (task: Task) => {
          const items: MenuProps['items'] = [
            {
              key: 'g1',
              type: 'group',
              children: [
                {
                  label: (
                    <StyledActionButton onClick={() => updateTask(task)}>
                      <Flex gap="s">
                        <AtiraIcon icon={faPenToSquare} color="main" />
                        <Text color="main">{t('common.edit')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  label: (
                    <StyledActionButton onClick={() => previewTask(task)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faEye} color="main" />
                        <Text color="main">{t('common.preview')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  label: (
                    <StyledActionButton
                      width={imgWidth}
                      onClick={() => deleteTask(task)}
                    >
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faTrashCan} color="main" />
                        <Text color="main">{t('common.delete')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
              ].map((child, index) => ({
                ...child,
                key: index,
                style: { padding: 4 },
              })),
            },
          ];

          return (
            <Flex justifyContent="center">
              <Dropdown
                menu={{ items }}
                placement="bottomLeft"
                arrow={{ pointAtCenter: true }}
                trigger={['click']}
              >
                <Tooltip title={t('common.menu')}>
                  <Button
                    icon={faEllipsis}
                    backgroundColor="transparent"
                    margin="0"
                    color="black"
                  />
                </Tooltip>
              </Dropdown>
            </Flex>
          );
        },
        reorder: true,
        center: true,
        width: '3rem',
        data: { column, enabled: true },
      };

    case 'name':
      return {
        title: t('common.name'),
        key: 'name',
        render: (task: Task) => {
          return (
            <Button
              onClick={() => previewTask(task)}
              backgroundColor="transparent"
              margin="0"
              padding="0"
              color="black"
            >
              {task.name}
            </Button>
          );
        },
        sorter: (task: Task, _task: Task) =>
          task.name.localeCompare(_task.name, 'en', { sensitivity: 'base' }),
        sortable: true,
        reorder: true,
        data: { column, enabled: true },
        width: getFieldWidth(column),
      };

    /** time is the document's created_at field */
    case 'createdAt':
      return {
        title: t('entries.table.header.submit_time'),
        key: 'time',
        render: ({ createdAt }: Task) =>
          standardDate(new Date(createdAt), true),
        sorter: (a: Task, b: Task) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateA - dateB;
        },
        sortable: true,
        reorder: true,
        width: '6rem',
        data: { column, enabled: true },
      };

    case 'reminder':
      return {
        title: t('common.date'),
        key: 'date',
        render: ({ reminder }: Task) =>
          reminder ? standardDate(new Date(reminder), true) : null,
        sorter: (_: Task, __: Task) => {
          if (_?.reminder && __?.reminder) {
            const dateA = new Date(_?.reminder).getTime();
            const dateB = new Date(__?.reminder).getTime();
            return dateA - dateB;
          } else {
            return 0;
          }
        },
        sortable: true,
        reorder: true,
        width: '6rem',
        data: { column, enabled: true },
      };

    case 'contactAttempts':
      return {
        title: t('common.contact_attempts'),
        key: 'contactAttempts',
        render: (_: Task) =>
          isNumber((_ as any)?.contactAttempts)
            ? getTruncatedString((_ as any)?.contactAttempts.toString(), 30)
            : null,
        sortable: true,
        reorder: true,
        data: { column, enabled: true },
        width: getFieldWidth(column),
      };

    default:
      return {
        title: column,
        key: column,
        render: (task: Task) =>
          // @ts-ignore
          getTruncatedString(task[column] || '', 30),
        sortable: true,
        reorder: true,
        data: { column, enabled: true },
        width: getFieldWidth(column),
      };
  }
};

export const createLeadTasksColumns = (functions: FieldFunctions) =>
  TableColumnKeys.map((t) => getFieldInfo(t, functions));
