import { CollapseProps } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useTheme } from 'styled-components';

import { AtiraCollapse } from '../../../../components/AtiraCollapse';
import { AtiraEmpty } from '../../../../components/AtiraEmpty';
import { Drawer } from '../../../../components/Drawer';
import { KanbanTask } from '../../../../model/kanban/KanbanTask';
import { KanbanTaskDetails } from './KanbanTaskDetails';

interface KanbanTasksReadDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  tasks: KanbanTask[] | undefined;
}

export const KanbanTasksReadDrawer: React.FC<KanbanTasksReadDrawerProps> = ({
  isOpen = false,
  tasks,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const items: CollapseProps['items'] = useMemo(() => {
    const panelStyle: CSSProperties = {
      borderBottom: '1px solid black',
      backgroundColor: theme.sub,
      border: '0',
    };

    if (tasks?.length) {
      return tasks.map((task, index) => ({
        key: task._id,
        label: task?.name,
        children: task ? <KanbanTaskDetails kanbanTask={task} /> : null,
        style: panelStyle,
      }));
    }

    return [];
  }, [tasks, theme.sub]);

  if (!tasks) {
    return null;
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={`${t('common.task_preview')}`}
      width={'30rem'}
    >
      {items.length ? (
        <AtiraCollapse items={items} accordion />
      ) : (
        <AtiraEmpty description={t('kanban.tasks.empty')} />
      )}
    </Drawer>
  );
};
