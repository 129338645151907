import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Rate, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { AtiraImage } from '../../../../components/Image';
import { Text } from '../../../../components/Text';
import { ContactTaskPriority } from '../../../../model/entry/types/ContactTaskPriority.enum';
import { ContactTask } from '../../../../model/task/ContactTask';
import { Spacing } from '../../../../theme/Spacing';
import { standardDate } from '../../../../utils/Date';
import { getMediaUrl } from '../../../../utils/Media';
import { useEntriesContext } from '../../entries-context';

const getTaskPriorityTextColor = (taskPriority?: ContactTaskPriority) => {
  switch (taskPriority) {
    case ContactTaskPriority.LOW:
      return 'green';
    case ContactTaskPriority.MEDIUM:
      return 'orange';
    case ContactTaskPriority.HIGH:
      return 'red';
  }
};

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  width: 7rem;
`;

const TaskImage = styled(AtiraImage)`
  max-width: 4rem;
  max-height: 4rem;
  width: 100%;
  height: 100%;
  align-self: center;
`;

const DeleteButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.red};
`;

interface ContactTaskDetailsProps {
  contactTask: ContactTask;
}

export const ContactTaskDetails: React.FC<ContactTaskDetailsProps> = ({
  contactTask,
}) => {
  const { t } = useTranslation();

  const {
    setContactTaskUpdateDrawerVisible,
    setContactTasksReadDrawerVisible,
    setTask,
    setDeleteTaskWarningModalVisible,
  } = useEntriesContext();

  const onOpenUpdateDrawer = () => {
    setContactTasksReadDrawerVisible(false);
    setTask(contactTask);
    setContactTaskUpdateDrawerVisible(true);
  };

  const onDeleteTask = () => {
    setTask(contactTask);
    setDeleteTaskWarningModalVisible(true);
  };

  return (
    <Flex flex={1} flexDirection="column" gap="m">
      <StyledFlex>
        <BoldText>{t('task.read.name')}</BoldText>
        <ValueText>{contactTask?.name}</ValueText>
      </StyledFlex>

      {contactTask?.deadline ? (
        <StyledFlex>
          <BoldText>{t('common.due_date')}:</BoldText>
          <Text>{standardDate(contactTask?.deadline)}</Text>
        </StyledFlex>
      ) : null}

      <StyledFlex>
        <BoldText>{t('common.priority')}:</BoldText>
        <Text
          style={{
            color: getTaskPriorityTextColor(contactTask?.priority),
          }}
        >
          {t(`common.${contactTask?.priority?.toLowerCase()}`)}
        </Text>
      </StyledFlex>

      {contactTask?.description ? (
        <Flex gap="s" flexWrap="wrap">
          <BoldText>{t('common.description')}:</BoldText>
          <Flex>
            <Text wordBreak="break-word">{contactTask?.description}</Text>
          </Flex>
        </Flex>
      ) : null}

      {contactTask?.image ? (
        <Flex gap="s" flexDirection="column" flexWrap="wrap">
          <BoldText>{t('common.image')}:</BoldText>
          <TaskImage preview src={getMediaUrl(contactTask.image)} />
        </Flex>
      ) : null}

      {contactTask?.rating ? (
        <Flex gap="s" flexDirection="column" flexWrap="wrap">
          <BoldText>{t('common.rating')}:</BoldText>
          <Rate count={10} disabled value={contactTask?.rating} />
        </Flex>
      ) : null}

      <Flex justifyContent="space-between" alignItems="center">
        <StyledButton onClick={onOpenUpdateDrawer}>
          {t('common.edit')}
        </StyledButton>

        <Tooltip title={t('common.delete')}>
          <DeleteButton icon={faTrash} iconWidth="2x" onClick={onDeleteTask} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
