import React, { Fragment } from 'react';

import { GenericGetStartedSection } from '../../components/public/GenericGetStartedSection';
import { UseCasesByIndustrySection } from './sections/UseCasesByIndustrySection';
import { UseCasesFeaturesSection } from './sections/UseCasesFeaturesSection';
import { UseCasesHeroSection } from './sections/UseCasesHeroSection';
import { UseCasesManageBusinessSection } from './sections/UseCasesManageBusinessSection';
import { UseCasesWhyChooseSection } from './sections/UseCasesWhyChooseSection';

export const UseCases: React.FC = () => {
  return (
    <Fragment>
      <UseCasesHeroSection />
      <UseCasesManageBusinessSection />
      <UseCasesByIndustrySection />
      <UseCasesFeaturesSection />
      <UseCasesWhyChooseSection />
      <GenericGetStartedSection />
    </Fragment>
  );
};
