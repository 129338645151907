import React, { ReactNode, createContext, useContext, useState } from 'react';

import { KanbanCard } from '../../model/kanban/KanbanCard';
import { KanbanTask } from '../../model/kanban/KanbanTask';

interface KanbanContextType {
  task: KanbanTask | undefined;
  setTask: ATVoidFunction<KanbanTask>;
  card: KanbanCard | undefined;
  setCard: ATVoidFunction<KanbanCard>;
  updateDrawerVisible: boolean;
  setUpdateDrawerVisible: ATVoidFunction<boolean>;
  readDrawerVisible: boolean;
  setReadDrawerVisible: ATVoidFunction<boolean>;
  createDrawerVisible: boolean;
  setCreateDrawerVisible: ATVoidFunction<boolean>;
  //
  taskCreateDrawerVisible: boolean;
  setTaskCreateDrawerVisible: ATVoidFunction<boolean>;
  tasksReadDrawerVisible: boolean;
  setTasksReadDrawerVisible: ATVoidFunction<boolean>;
  taskUpdateDrawerVisible: boolean;
  setTaskUpdateDrawerVisible: ATVoidFunction<boolean>;
  taskDeleteModalVisible: boolean;
  setTaskDeleteModalVisible: ATVoidFunction<boolean>;
}

const KanbanContext = createContext<KanbanContextType | undefined>(undefined);

export const useKanbanContext = () => {
  const context = useContext(KanbanContext);

  if (!context) {
    throw new Error('useKanbanContext must be used within a KanabnProvider');
  }

  return context;
};

interface KanbanContextProviderProps {
  children: ReactNode;
}

export const KanbanContextProvider: React.FC<KanbanContextProviderProps> = ({
  children,
}) => {
  const [card, setCard] = useState<KanbanCard | undefined>();
  const [task, setTask] = useState<KanbanTask | undefined>();
  const [updateDrawerVisible, setUpdateDrawerVisible] = useState(false);
  const [createDrawerVisible, setCreateDrawerVisible] = useState(false);
  const [readDrawerVisible, setReadDrawerVisible] = useState(false);
  //
  const [taskCreateDrawerVisible, setTaskCreateDrawerVisible] = useState(false);
  const [tasksReadDrawerVisible, setTasksReadDrawerVisible] = useState(false);
  const [taskUpdateDrawerVisible, setTaskUpdateDrawerVisible] = useState(false);
  const [taskDeleteModalVisible, setTaskDeleteModalVisible] = useState(false);

  return (
    <KanbanContext.Provider
      value={{
        task,
        setTask,
        card,
        setCard,
        setUpdateDrawerVisible,
        updateDrawerVisible,
        setReadDrawerVisible,
        readDrawerVisible,
        setTaskCreateDrawerVisible,
        taskCreateDrawerVisible,
        setTasksReadDrawerVisible,
        tasksReadDrawerVisible,
        createDrawerVisible,
        setCreateDrawerVisible,
        taskUpdateDrawerVisible,
        setTaskUpdateDrawerVisible,
        taskDeleteModalVisible,
        setTaskDeleteModalVisible,
      }}
    >
      {children}
    </KanbanContext.Provider>
  );
};
