import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Spacing } from '../theme/Spacing';
import { Button } from './Button';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { Text } from './Text';

interface BlogProps {
  post: any;
}

const PostContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${Spacing.m};
  padding: ${Spacing.s};
  border: 0.2rem solid ${(props) => props.theme.main};
`;

const PostInfo = styled(Flex)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${Spacing.s};
  gap: ${Spacing.m};
`;

export const Blog: React.FC<BlogProps> = ({ post }) => {
  const { t } = useTranslation();

  const onReadMore = () => {
    window.open(post.link);
  };

  return (
    <Flex flex={1} alignItems="center" justifyContent="center" padding="s">
      <PostContainer>
        <Flex justifyContent="center">
          <AtiraImage
            src={post.jetpack_featured_media_url}
            alt="post-photo"
            style={{ width: '100%', borderRadius: '0.5rem' }}
          />
        </Flex>

        <PostInfo>
          <Text align="center" fontSize="l">
            {post.title.rendered}
          </Text>
          <hr style={{ width: '100%' }} />
          <Button width="100%" height="3rem" onClick={onReadMore}>
            {t('common.read_more')}
          </Button>
        </PostInfo>
      </PostContainer>
    </Flex>
  );
};
