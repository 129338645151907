import { CollapseProps } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../../ThemeContext';
import { AtiraCollapse } from '../../../../components/AtiraCollapse';
import { AtiraEmpty } from '../../../../components/AtiraEmpty';
import { Drawer } from '../../../../components/Drawer';
import { LeadTask } from '../../../../model/task/LeadTask';
import { useEntriesContext } from '../../entries-context';
import { LeadTaskDetails } from './LeadTaskDetails';

interface LeadTasksReadDrawerProps {
  tasks: LeadTask[] | undefined;
  isOpen: boolean;
  onClose: VoidFunction;
}
export const LeadTasksReadDrawer: React.FC<LeadTasksReadDrawerProps> = ({
  tasks,
  isOpen = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const {
    setTask,
    setLeadTasksReadDrawerVisible,
    setDeleteTaskWarningModalVisible,
    setLeadTaskUpdateDrawerVisible,
  } = useEntriesContext();

  const items: CollapseProps['items'] = useMemo(() => {
    if (tasks?.length) {
      const panelStyle: React.CSSProperties = {
        borderBottom: '1px solid black',
        backgroundColor: theme.sub,
        border: '0',
      };

      return tasks.map((task, index) => ({
        key: `${index + 1}`,
        label: task?.name,
        children: task ? (
          <LeadTaskDetails
            leadTask={task}
            onSetTask={setTask}
            onSetReadDrawerVisible={setLeadTasksReadDrawerVisible}
            onSetUpdateDrawerVisible={setLeadTaskUpdateDrawerVisible}
            onSetDeleteTaskWarningModalVisible={
              setDeleteTaskWarningModalVisible
            }
          />
        ) : null,
        style: panelStyle,
      }));
    }

    return [];
  }, [
    setDeleteTaskWarningModalVisible,
    setLeadTaskUpdateDrawerVisible,
    setLeadTasksReadDrawerVisible,
    setTask,
    tasks,
    theme.sub,
  ]);

  if (!tasks) {
    return null;
  }

  return (
    <Drawer
      title={t('tasks.read.drawer.title')}
      open={isOpen}
      onClose={onClose}
      width={'30rem'}
    >
      {items.length ? (
        <AtiraCollapse items={items} accordion />
      ) : (
        <AtiraEmpty description={t('tasks.lead.empty')} />
      )}
    </Drawer>
  );
};
