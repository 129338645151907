import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import { Text } from '../components/Text';
import { Modal } from '../components/modal/Modal';
import { useAppDispatch } from '../redux/store';
import { userActions } from '../redux/user/user.slice';
import { AtiraToast } from '../utils/AtiraToast';

const StyledModal = styled(Modal)`
  .ant-modal-title button {
    display: none;
  }
  .ant-modal-title p {
    margin: auto;
  }
`;

export const MobileBlockingModal: React.FC = () => {
  const { t } = useTranslation();

  const dispacth = useAppDispatch();
  const navigate = useNavigate();

  const onBackHome = async () => {
    try {
      await dispacth(userActions.logout()).unwrap();
      navigate('/home');

      AtiraToast.success(t('logout.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    }
  };

  return (
    <StyledModal title={t('welcome_message')} open>
      <Flex flexDirection="column" gap="m">
        <Text align="center">{t('small_screens_warning_description')}</Text>

        <Text color="darkerSub" align="center">
          {t('mobile_block.logout_description')}
        </Text>

        <Button margin="0" fontSize="1.2rem" onClick={onBackHome}>
          {t('common.back_home')}
        </Button>
      </Flex>
    </StyledModal>
  );
};
