import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { WarningModal } from '../../../components/WarningModal';
import { PageMeta } from '../../../model/meta/PageMeta';
import { LeadTask } from '../../../model/task/LeadTask';
import { TaskKind } from '../../../model/task/types/TaskKind.eum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { taskActions } from '../../../redux/task/task.slice';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { LeadTaskDetails } from '../../entries/components/lead-tasks/LeadTaskDetails';
import { LeadTaskUpdateDrawer } from '../../entries/components/lead-tasks/LeadTaskUpdateDrawer';
import { useTaskContext } from '../task-context';

const StyledLeadTasksReadDrawer = styled(Drawer)`
  .ant-drawer-mask {
    z-index: 999 !important;
  }
`;

export const TasksDrawers: React.FC = () => {
  const [deleteTaskLoading, setDeleteTaskLoading] = useState(false);

  const {
    task,
    setTask,
    //
    leadTasksReadDrawerVisibile,
    setLeadTasksReadDrawerVisible,
    //
    setDeleteTaskWarningModalVisible,
    deleteTaskWarningModalVisible,
    //
    leadTaskUpdateDrawerVisibile,
    setLeadTaskUpdateDrawerVisible,
  } = useTaskContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onCloseLeadTaskUpdateReadDrawer = async () => {
    try {
      await dispatch(
        taskActions.getLeadTasks({ userId, meta: PageMeta.create() }),
      ).unwrap();
      setLeadTaskUpdateDrawerVisible(false);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    }
  };

  const deleteTask = async () => {
    try {
      setDeleteTaskLoading(true);

      if (task?.kind === TaskKind.LEAD) {
        await dispatch(
          entryActions.deleteLeadTask({ taskId: task?._id!, userId }),
        ).unwrap();
        await dispatch(
          taskActions.getLeadTasks({ userId, meta: PageMeta.create() }),
        ).unwrap();
      } else if (task?.kind === TaskKind.CONTACT) {
        await dispatch(
          entryActions.deleteContactTask({ taskId: task?._id!, userId }),
        ).unwrap();
        await dispatch(
          taskActions.getContactTasks({ userId, meta: PageMeta.create() }),
        ).unwrap();
      } else if (task?.kind === TaskKind.KANBAN) {
        await dispatch(
          kanbanActions.deleteKanbanTask({ userId, taskId: task?._id! }),
        ).unwrap();
        await dispatch(
          taskActions.getKanbanTasks({ userId, meta: PageMeta.create() }),
        ).unwrap();
      }

      setDeleteTaskWarningModalVisible(false);

      AtiraToast.success(t('tasks.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setDeleteTaskLoading(false);
    }
  };

  return (
    <Fragment>
      <Flex>
        <StyledLeadTasksReadDrawer
          title={t('common.task_preview')}
          open={leadTasksReadDrawerVisibile}
          onClose={() => setLeadTasksReadDrawerVisible(false)}
        >
          <LeadTaskDetails
            leadTask={task as LeadTask}
            onSetTask={setTask}
            onSetReadDrawerVisible={setLeadTasksReadDrawerVisible}
            onSetUpdateDrawerVisible={setLeadTaskUpdateDrawerVisible}
            onSetDeleteTaskWarningModalVisible={
              setDeleteTaskWarningModalVisible
            }
          />
        </StyledLeadTasksReadDrawer>

        <LeadTaskUpdateDrawer
          leadTask={task as LeadTask}
          isOpen={leadTaskUpdateDrawerVisibile}
          onClose={onCloseLeadTaskUpdateReadDrawer}
          onSetReadDrawerVisible={setLeadTasksReadDrawerVisible}
        />

        <WarningModal
          isOpen={deleteTaskWarningModalVisible}
          loading={deleteTaskLoading}
          onConfirm={deleteTask}
          onClose={() => setDeleteTaskWarningModalVisible(false)}
          title={t('tasks.delete.warning.title', { task_name: task?.name })}
          description={t('tasks.delete.warning.description')}
        />
      </Flex>
    </Fragment>
  );
};
