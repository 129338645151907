import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';

export const DealTasksTab: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Text>{t('common.feature.coming_soon')}</Text>
    </Flex>
  );
};
