import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraColumn } from '../../entries/components/AtiraColumn';

const Container = styled(Flex)`
  padding-inline: ${Spacing.s};
  padding-block: ${Spacing.xl};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: calc(${Spacing.xxxl} * 1.3);
    padding-block: ${Spacing.xxxl};
  }
`;

const Title = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: ${Spacing.s};
  font-weight: bold;
  color: ${({ theme }) => theme.main};

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 2rem;
  }
`;

const FeatureText = styled(Text)`
  font-size: 1.25rem;
  line-height: 2rem;
`;

const IconWrapper = styled(Flex)`
  margin-top: 0.4rem;
`;

const FeatureWrapper = styled(Flex)`
  gap: ${Spacing.m};
`;

const CallToAction = styled(Link)`
  text-decoration: none;
  font-size: 1rem;
  padding: ${Spacing.s} ${Spacing.m};
  color: ${({ theme }) => theme.sub};
  background-color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.md};
  transition: all 0.3s;
  width: fit-content;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

export const UseCasesWhyChooseSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AtiraRow align={'middle'} gutter={20}>
        <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={12}>
          <Flex flexDirection="column" gap="m">
            <Title>{t('use_cases.why_choose.title')}</Title>

            <FeatureText color="main">
              {t('use_cases.why_choose.paragraph')}
            </FeatureText>

            <Flex flexDirection="column" gap="m">
              <FeatureWrapper>
                <IconWrapper>
                  <AtiraIcon icon={faCheckSquare} color="main" size="xl" />
                </IconWrapper>

                <FeatureText>{t('use_cases.why_choose.reason.1')}</FeatureText>
              </FeatureWrapper>

              <FeatureWrapper>
                <IconWrapper>
                  <AtiraIcon icon={faCheckSquare} color="main" size="xl" />
                </IconWrapper>

                <FeatureText>{t('use_cases.why_choose.reason.2')}</FeatureText>
              </FeatureWrapper>

              <FeatureWrapper>
                <IconWrapper>
                  <AtiraIcon icon={faCheckSquare} color="main" size="xl" />
                </IconWrapper>

                <FeatureText>{t('use_cases.why_choose.reason.3')}</FeatureText>
              </FeatureWrapper>

              <FeatureWrapper>
                <IconWrapper>
                  <AtiraIcon icon={faCheckSquare} color="main" size="xl" />
                </IconWrapper>

                <FeatureText>{t('use_cases.why_choose.reason.4')}</FeatureText>
              </FeatureWrapper>

              <FeatureWrapper>
                <IconWrapper>
                  <AtiraIcon icon={faCheckSquare} color="main" size="xl" />
                </IconWrapper>

                <FeatureText>{t('use_cases.why_choose.reason.5')}</FeatureText>
              </FeatureWrapper>
            </Flex>
          </Flex>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={12}>
          <Flex flexDirection="column" gap="s">
            <AtiraImage src={Images.CasesWhyChoose} />

            <CallToAction to={''}>{t('pricing.section_c2a')}</CallToAction>
          </Flex>
        </AtiraColumn>
      </AtiraRow>
    </Container>
  );
};
