import { DragEndEvent, DragOverEvent } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Table, TableProps } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../../../components/Flex';
import { DNDContext } from '../../../../components/dnd/DNDContext';
import { DNDSortableContext } from '../../../../components/dnd/DNDSortableContext';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { AppLangs } from '../../../../i18n';
import { EntriesTableSettings } from '../../../../model/entries-table-settings/EntriesTableSettings';
import { Entry } from '../../../../model/entry/Entry';
import { entrySliceSelectors } from '../../../../redux/entry/entry.selector';
import { entryActions } from '../../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { AtiraToast } from '../../../../utils/AtiraToast';
import { EntriesTableWithDNDBodyCell } from './EntriesTableWithDNDBodyCell';
import { DragIndexContext } from './EntriesTableWithDNDContext';
import { EntriesTableWithDNDHeaderCell } from './EntriesTableWithDNDHeaderCell';
import { EntiresTableWithDNDOverlay } from './EntriesTableWithDNDOverlay';
import { DragIndexState } from './types/DragIndexState';

interface EntiresTableWithDNDProps extends Omit<TableProps, 'columns'> {
  data: Entry[];
  columns: TableProps['columns'];
  loading?: boolean;
  selectedRowKeys?: React.Key[];
  onSelectedRowKeysChange?: ATVoidFunction<React.Key[]>;
}

const StyledTableWrapper = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: 0.1rem solid black;
  }

  .ant-table-wrapper .ant-table-thead > tr > th:nth-child(2),
  .ant-table-wrapper .ant-table-thead > tr > th:nth-child(3) {
    text-align: center;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-right: 0.01rem solid lightgrey;
  }

  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr:hover > td {
    background-color: inherit !important;
  }

  .ant-table-tbody > tr > td {
    padding: 0 8px !important;
    height: 2.5rem;
  }

  .ant-table-tbody > tr > td.ant-table-cell.lead-cell {
    background-color: ${({ theme }) => theme.lightAccent} !important;
  }

  .ant-table-tbody > tr > td.ant-table-cell.contact-cell {
    background-color: ${({ theme }) => theme.lightMain} !important;
  }

  .ant-table-tbody > tr > td.ant-table-cell.junk-cell {
    background-color: ${({ theme }) => theme.darkSub} !important;
  }

  .ant-table-tbody > tr > td.ant-table-cell-fix-left-last {
    background-color: ${({ theme }) => theme.white} !important;
    border-right: 3px solid ${({ theme }) => theme.black};
  }

  .ant-table-thead > tr > th.ant-table-cell-fix-left-last {
    background-color: ${({ theme }) => theme.white} !important;
    border-right: 3px solid ${({ theme }) => theme.black};
  }

  .ant-table-tbody > tr > td.ant-table-cell-fix-right-last {
    background-color: ${({ theme }) => theme.white} !important;
    border-left: 3px solid ${({ theme }) => theme.black};
  }

  .ant-table-thead > tr > th.ant-table-cell-fix-right-last {
    background-color: ${({ theme }) => theme.white} !important;
    border-left: 3px solid ${({ theme }) => theme.black};
  }

  .ant-table-tbody > tr > td.ant-table-cell {
    background-color: ${({ theme }) => theme.white} !important;
  }
`;

export const EntiresTableWithDND: React.FC<EntiresTableWithDNDProps> = ({
  data,
  columns: _columns,
  loading = false,
  selectedRowKeys,
  onSelectedRowKeysChange,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { height } = useWindowDimensions();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );

  const [columns, setColumns] = useState(() =>
    (_columns || []).map((c, i) => ({
      ...c,
      key: `${i}`,
      onHeaderCell: () => ({ id: `${i}` }),
      onCell: () => ({ id: `${i}` }),
    })),
  );

  const [dragIndex, setDragIndex] = useState<DragIndexState>({
    active: -1,
    over: -1,
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      onSelectedRowKeysChange?.(newSelectedRowKeys);
    },
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let newColumns: typeof columns = [];

      setColumns((prevState) => {
        const activeIndex = prevState.findIndex((i) => i.key === active?.id);
        const overIndex = prevState.findIndex((i) => i.key === over?.id);
        const cols = arrayMove(prevState, activeIndex, overIndex);
        newColumns = cols;
        return cols;
      });

      debouncedOnUpdateColumnsOrder(newColumns);
    }
    setDragIndex({ active: -1, over: -1 });
  };

  const onDragOver = ({ active, over }: DragOverEvent) => {
    const activeIndex = columns.findIndex((i) => i.key === active.id);
    const overIndex = columns.findIndex((i) => i.key === over?.id);
    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? 'right' : 'left',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdateColumnsOrder = useCallback(
    debounce(async (newColumns: typeof columns) => {
      try {
        await dispatch(
          entryActions.updateUserTableSettings({
            userId,
            tableId: tableSettings?._id!,
            columns: newColumns.map((c: any, index) => ({
              ...c.data,
              order: index,
            })) as Partial<EntriesTableSettings['columns']>,
          }),
        ).unwrap();
        await dispatch(entryActions.getUserTableSettings({ userId })).unwrap();
      } catch (e: any) {
        AtiraToast.apiError(e);
        console.log(e);
      }
    }, 1000),
    [dispatch, tableSettings?._id, userId],
  );

  return (
    <Flex marginTop="m" flexDirection="column" width="100%">
      <StyledTableWrapper>
        <DNDContext onDragEnd={onDragEnd} onDragOver={onDragOver}>
          <DNDSortableContext
            items={data.map((x) => x._id)}
            strategy={horizontalListSortingStrategy}
          >
            <DragIndexContext.Provider value={dragIndex}>
              <Table
                dataSource={data.map((x) => ({ ...x, key: x._id }))}
                rowSelection={rowSelection}
                columns={columns.filter(
                  ({ data: { enabled } }: any) => enabled,
                )}
                locale={{ emptyText: t('entries.no_entries') }}
                scroll={{ x: 2000, y: height - 310 }}
                loading={loading}
                style={{ overflowY: 'hidden', height: '100%' }}
                direction={i18n.language === AppLangs.AR ? 'rtl' : 'ltr'}
                components={{
                  header: { cell: EntriesTableWithDNDHeaderCell },
                  body: { cell: EntriesTableWithDNDBodyCell },
                }}
                size="small"
                {...props}
              />
            </DragIndexContext.Provider>
          </DNDSortableContext>
          <EntiresTableWithDNDOverlay columns={columns} dragIndex={dragIndex} />
        </DNDContext>
      </StyledTableWrapper>
    </Flex>
  );
};
