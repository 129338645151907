import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Images } from '../../assets';
import { AtiraIcon } from '../../components/AtiraIcon';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { Spacing } from '../../theme/Spacing';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.m};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.SM};
  border-radius: ${Rounded.lg};
  width: fit-content;
  margin: ${Spacing.s} auto;
  padding: ${Spacing.m};
  flex-grow: 1;
`;

const CallToAction = styled.a`
  text-decoration: none;
  font-size: 1rem;
  padding: ${Spacing.s} ${Spacing.m};
  color: ${({ theme }) => theme.sub};
  background-color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.md};
  transition: all 0.3s;
  width: fit-content;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

const StyledText = styled(Text)`
  text-align: center;
  white-space: pre-line;
  font-size: 1.25rem;
`;

export const Subscription: React.FC = () => {
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <Container>
      <Text fontSize="xl">{t('subscription.page.title')}</Text>

      <StyledText>{t('subscription.info')}</StyledText>

      <Flex flexDirection="column" gap="m" width={'60%'}>
        <Text fontSize="l" align="center">
          {`${t('subscription.status')} `}
          <span style={{ color: theme.main }}>
            {t(`common.${loggedInUser?.kind.toLowerCase()}`)}
          </span>
        </Text>

        <Text fontSize="l">{t('subscription.plan.features')}</Text>

        <Flex flexDirection="column" gap="s">
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" />

            <Text fontSize="xm">{t('common.user')}</Text>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" />

            <Text fontSize="xm">
              {t('pricing.planCard.entriesCount', { count: 500 })}
            </Text>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" />

            <Text fontSize="xm">
              {t('pricing.planCard.formsCount', { count: 3 })}
            </Text>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" />

            <Text fontSize="xm">{`1 ${t('common.pipeline')}`}</Text>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" />

            <Text fontSize="xm">{`3 ${t('common.custom_fields')}`}</Text>
          </Flex>

          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faCircle} color="main" />

            <Text fontSize="xm">
              {`${t('pricing.hero.plan_card.fearure.app')} `}
              <span style={{ fontStyle: 'italic' }}>({t('common.soon')})</span>
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection="column">
        <StyledText style={{ textAlign: 'start' }}>
          {t('subscription.info.contact')}
        </StyledText>

        <Flex alignItems="center" gap="s" justifyContent="center">
          <CallToAction target="_blank" href="https://wa.me/905335589040">
            {t('common.contact_whatsapp')}
          </CallToAction>

          <CallToAction target="_blank" href="mailto:support@atiragrup.com">
            {t('common.contact_support')}
          </CallToAction>

          <CallToAction target="_blank" href="mailto:sales@atiragrup.com">
            {t('common.contact_sales')}
          </CallToAction>
        </Flex>
      </Flex>

      <Flex maxWidth={'15rem'}>
        <AtiraImage src={Images.AtiraSpaceLogoBetaMain} />
      </Flex>
    </Container>
  );
};
